import React,{useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,    
    TableContainer,TableHead,TableRow,Tooltip,TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; 
import PaginacionTab from '../layout/PaginacionTab'
import EditarUsuario from './EditarUsuario'
import EditarStatus from './EditarStatusUsu'
import {findNumberCad} from '../funciones/FindNumber'
const useStyles = makeStyles({
    root: {//width: '100%',
    },
    container: { //maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
      maxWidth:'100%',
      minWidth:44+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const columnas=[          
    { id:1, titulo:"Nombre", alinear:'center' },   
    { id:4, titulo:"Cargo", alinear:'center'},          
    { id:3, titulo:"Superior", alinear:'center'},          
    { id:2, titulo:"Estructura", alinear:'center'},           
    //{ id:4, titulo:"Alta", alinear:'center'    },  
  ];

const TablaUsuarios = ({listUsu, auxActualizar, setActualizar, listTipoUsu, recargar, listEstruc, listTitular ,usrRegistro, setUsrRegistro,listTitularEsp}) => {
  const classes = useStyles();  
  const nvl= parseInt(localStorage.getItem('Tipo'));    
  const usuID = parseInt(localStorage.getItem('UsuId'));        
  const [datos, setDatos] = useState([])  
  const[sinDatos,guardarSinDatos]= useState(true)
  const [espera,setEspera]= useState(false);  
  const [usuSelecc,setUsuSelecc]= useState([]); 
  const [modalEditCliAbierto,setModalEditCliAbierto]= useState(false); 
  const [modalEditStdUsu,setModalEditStdUsu]= useState(false);        
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [alturaTabla,setAlturaTabla] = useState(150)
  const cadena = localStorage.getItem("Operacion")+"|";
  const fondo=process.env.REACT_APP_Fondo_Color
  let limiteXPag=150
  React.useEffect(()=>
  {
    //console.log(listUsu);
    if(listUsu.length>limiteXPag)
    { let auxTam = listUsu.length;
      if(auxTam%limiteXPag===0)
      { let pagina=Math.trunc(auxTam/limiteXPag)
        setTotalPaginas(pagina)   
      }
      else
      { let pagina=Math.trunc(auxTam/limiteXPag)
        pagina=pagina+1;
        setTotalPaginas(pagina)
      }
      setNumPagina(0) 
      setDatos(listUsu.slice(0,limiteXPag))
    }
    else
    { setTotalPaginas(1)
      setNumPagina(0)
      setDatos(listUsu)
    }

    let auxAltura = window.innerHeight<937 ? 
      (listUsu.length>limiteXPag ? window.innerHeight-220 : window.innerHeight-170) 
    : (listUsu.length>limiteXPag ? window.innerHeight-220 : window.innerHeight-220)

    setAlturaTabla(auxAltura)
    
               
  },[listUsu])

  const seleccionado=(auxId,auxOrden)=>{
    setUsrRegistro(auxOrden)   
  }
   
  function abrirModalEditCliente(auxClient)
  {  setUsuSelecc(auxClient)
    //console.log("abriendo modal");  
    setModalEditCliAbierto(true);    
  }

  function abrirModalEditStdUsu(auxClient)
  { setUsuSelecc(auxClient)
    //console.log("abriendo modal");  
    setModalEditStdUsu(true);    
  }
   
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setDatos(listUsu.slice(0,limiteXPag))   
    }
    else
    {
      setDatos(listUsu.slice(newPage * limiteXPag, newPage * limiteXPag + limiteXPag))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false} colSpan={3} 
      rowsPerPage={limiteXPag} count={listUsu.length}
      page={numPagina} lang='es' onPageChange={onChangePagina}
      labelDisplayedRows={
        ({ from, to, count }) => { return '' + from + '-' + to + ' de ' + count }
      }
      ActionsComponent={PaginacionTab}
    />)
  }

  function Renglon(props)
  {
    const { row } = props;  
    const auxColorStd=row.Status===1 ? "#0158A5":"#d40100";    
    const permiso= usuID===1 || ( row.UsrId!==1  && (nvl===1 || usuID=== row.UsrId || nvl < row.Tipo)|| ( row.Tipo===2 && nvl!==2) ||findNumberCad('|2|',cadena) )
    return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.UsrId,row); } }
        className={classes.tableRow} selected={ usrRegistro?.UsrId === row.UsrId}
        //onContextMenu={(event)=>guadarPosCursor(event,row)} 
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >            
        <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          {permiso ?
            <Tooltip title={`Editar Estatus`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditStdUsu(row)} component="span">
              {usrRegistro?.UsrId === row.UsrId ? 
                <FiberManualRecordIcon style={{color:'white',width:18+'px'}} />
                :<FiberManualRecordIcon style={{width:18+'px',color: auxColorStd }} color="primary"/>
              }
            </IconButton>                            
          </Tooltip>: ""}
        </TableCell>
        <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          { permiso ?<Tooltip title={`Editar Usuario`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
              {usrRegistro?.UsrId === row.UsrId ? 
                <CreateIcon style={{color:'white',width:18+'px'}} />
                :<CreateIcon style={{width:18+'px'}} color="primary"/>
              }
            </IconButton>                            
          </Tooltip>
          :""}                                                                                                                                      
        </TableCell>               
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:300+'px',fontSize:12+'px'}} >
          {row.Tipo===5 && row?.MunNom !== undefined ? row.MunNom+" "+row.Nombre : row.Nombre}
        </TableCell>    
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:140+'px',fontSize:12+'px', paddingLeft:".5rem"}} >
          {row.Cargo}
        </TableCell>    
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:220+'px',fontSize:10+'px', paddingLeft:".5rem"}} >
          {row.SupNom}
        </TableCell>               
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:100+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.TpEstrNom}              
        </TableCell>        
      </TableRow>        
    </React.Fragment>
  );}

  function tablaCompleta(auxlista){  
    const backEstilo = { backgroundColor:fondo,color:'white'}
    return(
    <TableContainer className={classes.container} style={{height:alturaTabla}} >
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>     
          <TableCell style={backEstilo} key={1} padding={'none'} ></TableCell>
          <TableCell style={backEstilo} key={2} align={'center'} padding={'none'} >  
            {auxlista.length}            
          </TableCell>                                            
          {columnas.map(row => {              
            return(  
            <TableCell style={backEstilo} key={row.id+2} align={row.alinear} padding={'none'} >
              {row.titulo}
            </TableCell>    
            )                
          })}           
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length!=0 ? (auxlista.map((row) => (
          <Renglon key={row.UsrId} row={row} />
        )))
        :<TableRow className={classes.tableRow}>                  
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="center">Usuarios</TableCell>                       
          <TableCell  className={classes.tableCell} align="center">Registrado</TableCell>                               
        </TableRow>
        }
      </TableBody>
    </Table>
    </TableContainer>
    )
  }

  const paginacion = totalPaginas>1 ? generarFooter() : null;
  const contenido =tablaCompleta(datos)
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )  
                                        
  const modEditCli = modalEditCliAbierto ? 
                    ( <EditarUsuario modalAbierto={modalEditCliAbierto}  
                        setModalAbierto={setModalEditCliAbierto} usrSelecc={usuSelecc}
                        titulo={'Editar '+ usuSelecc.Nombre }                          
                        setActualizar={setActualizar} actualizar={auxActualizar} listTipoUsu ={listTipoUsu }
                        subtitulo={ usuSelecc.Tipo === 5 ? usuSelecc.MunNom :"" }
                        recargar={recargar} listEstruc={listEstruc}  listTitular={listTitular}
                        listTitularEsp={listTitularEsp}
                      />
                    ): null;     
  const modEditStdUsu = modalEditStdUsu ? 
                      ( <EditarStatus modalAbierto={modalEditStdUsu}  
                          setModalAbierto={setModalEditStdUsu} usrSelecc={usuSelecc}
                          titulo={'Editar Usuario'  }                          
                          setActualizar={setActualizar} actualizar={auxActualizar} 
                        />
                      ): null;     
                                                                                                                                            
  return (
  <div style={{minWidth:36+'rem',marginTop:.5+'rem',width:65+'rem',marginRight:".7rem"}}>        
    {tabla}
    {paginacion}
    {modEditStdUsu}
    {modEditCli}
  </div>
  )
}

export default TablaUsuarios