import React,{useEffect,useState} from 'react';
import {HorizontalBar,Bar} from 'react-chartjs-2';
import {Card,CardContent,Box, Typography} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:10+'rem',marginRight:".3rem"
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function Graficas({valor}){
  const classes = useStyles();
  const color1= '#19A11E';  //ff1744  
  const color2= '#FBBE06';
  const auxColor=process.env.REACT_APP_Fondo_Color
  const [data,setData]= useState([]);
  const [datEdad,setDatEdad]= useState([]);
  const [dataRG,setDataRG]= useState([]);
  const [dataRC,setDataRC]= useState([]);

  const [dataH20,setDataH20]= useState([]);
  const [dataH40,setDataH40]= useState([]);
  const [dataH60,setDataH60]= useState([]);
  const [dataH61,setDataH61]= useState([]);  
  const [dataM20,setDataM20]= useState([]);
  const [dataM40,setDataM40]= useState([]);
  const [dataM60,setDataM60]= useState([]);
  const [dataM61,setDataM61]= useState([]);
  
  const [dataH,setDataH]= useState([]);
  const [dataM,setDataM]= useState([]);

  const [dataBarH,setDataBarH]= useState([]);
  const [dataBarM,setDataBarM]= useState([]);

  const [bandera,setBandera]= useState(false);
  useEffect(()=>{    
    const{ Id, Nom, Edad25H,
      Edad40H, Edad60H, Edad61H,
      Edad25M, Edad40M, Edad60M,
      Edad61M, GenH, GenM,
      HTot, MTot, XTot,
      Tot25, Tot35, Tot45,
      Tot55, Tot65, Tot70,
      Edad25TpObsvrGenH, Edad40TpObsvrGenH,
      Edad60TpObsvrGenH, Edad25TpRgGenH,
      Edad40TpRgGenH,Edad60TpRgGenH,
      Edad25TpRcGenH, Edad40TpRcGenH,
      Edad60TpRcGenH, Edad25TpObsvrGenM,
      Edad40TpObsvrGenM, Edad60TpObsvrGenM,
      Edad25TpRgGenM, Edad40TpRgGenM,
      Edad60TpRgGenM, Edad25TpRcGenM,
      Edad40TpRcGenM, Edad60TpRcGenM,
      TpObsvrGenH, TpObsvrGenM,
      TpRgGenH, TpRgGenM, TpRcGenH,
      TpRcGenM }=valor[0]            
    //setDataH20({Edad25TpObsvrGenH,Edad25TpRgGenH,Edad25TpRcGenH})
    //setDataH40({Edad40TpObsvrGenH,Edad40TpRgGenH,Edad40TpRcGenH})
    //setDataH60({Edad60TpObsvrGenH,Edad60TpRgGenH,Edad60TpRcGenH})
    setDataH20({HombreOBS:Edad25TpObsvrGenH,HombreRG:Edad25TpRgGenH,HombreRC:Edad25TpRcGenH})
    setDataH40({HombreOBS:Edad40TpObsvrGenH,HombreRG:Edad40TpRgGenH,HombreRC:Edad40TpRcGenH})
    setDataH60({HombreOBS:Edad60TpObsvrGenH,HombreRG:Edad60TpRgGenH,HombreRC:Edad60TpRcGenH})
    

    setDataM20({MujerOBS:Edad25TpObsvrGenM,MujerRG:Edad25TpRgGenM,MujerRC:Edad25TpRcGenM})
    setDataM40({MujerOBS:Edad40TpObsvrGenM,MujerRG:Edad40TpRgGenM,MujerRC:Edad40TpRcGenM})
    setDataM60({MujerOBS:Edad60TpObsvrGenM,MujerRG:Edad60TpRgGenM,MujerRC:Edad60TpRcGenM})
    
    setDataH({Obsvervador:TpObsvrGenH,Rg:TpRgGenH,Rc:TpRcGenH})
    setDataM({Obsvervador:TpObsvrGenM,Rg:TpRgGenM,Rc:TpRcGenM})

    setDataBarH({HombreOBS:TpObsvrGenH,HombreRG:TpRgGenH,HombreRC:TpRcGenH})
    setDataBarM({MujerOBS:TpObsvrGenM,MujerRG:TpRgGenM,MujerRC:TpRcGenM})
    
    setData({Hombre:HTot,Mujer:MTot,Binario:XTot})
    setDatEdad({Tot25:Tot25, Tot35:Tot35, Tot45:Tot45,
      Tot55:Tot55, Tot65:Tot65, Tot70:Tot70,})
    //setDataRG({Rg:TpRg})
    //setDataRC({Rc:TpRc})
    setBandera(true)
  },[valor])

  const dataGenero = {
    labels: ['Género'],
    datasets: [
    { type: 'bar', label:[ 'No Binario' ],
        data:[data.Binario],//[10, 20, 30, 40],
        backgroundColor:  [ "#4BAC7D50"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'Hombre Total',
        type: 'bar',
        data: [data.Hombre], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'Mujer Total',
        type: 'bar',
        data: [data.Mujer], //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4   
        
      },   
    ],
  }; 

  const dataEdad = {
    labels: ['Edad'],
    datasets: [
    { type: 'bar', label:[ '18 a 25' ],
        data:[datEdad.Tot25],//[10, 20, 30, 40],
        backgroundColor:  [ "#4BAC7D50"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'26 a 35',
        type: 'bar',
        data: [datEdad.Tot35], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'36 a 45',
        type: 'bar',
        data: [datEdad.Tot45], //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'46 a 55',
        type: 'bar',
        data: [datEdad.Tot55], //[50, 40, 30, 50],
        backgroundColor:'#B406D8',
        borderColor: '#B406D8',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'56 a 65',
        type: 'bar',
        data: [datEdad.Tot65], //[50, 40, 30, 50],
        backgroundColor:'#FFA318',
        borderColor: '#FFA318',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'Mayor de 65',
        type: 'bar',
        data: [datEdad.Tot75], //[50, 40, 30, 50],
        backgroundColor:'#7C594C',
        borderColor: '#7C594C',
        //fill: false,
        tension: 0.4   
        
      },   
    ],
  }; 

  const Grafica2 = {
    labels: ["HombreOBS","HombreRG","HombreRC" ],
    datasets: [    
      { label:'Avance',
      type: 'bar',
      data: dataBarH, 
      backgroundColor:'#4BAC7D50',
      borderColor: '#4BAC7D50',
      //fill: false                                    
    },
      { label:'Hombre 20',
        type: 'line',
        data: dataH20,//[50, 40, 30, 50],
        backgroundColor:color2,
        borderColor: color2,
        //fill: false  ,                           
        tension: 0.4
        
      },  
      { label:'Hombre 40',
        type: 'line',
        data: dataH40, //[50, 40, 30, 50],
        backgroundColor:'rgb(54, 162, 235)',
        borderColor: 'rgb(54, 162, 235)',
        //fill: false                             ,
        tension: 0.4
      },  
      { label:'Hombre 60',
        type: 'line',
        data: dataH60, //[50, 40, 30, 50],
        backgroundColor:'#347AF4',    
        borderColor: '#347AF4',
        //fill: false,
        tension: 0.4  
      },  
 
     
    ],
  }; 

  const Grafica3 = {
    labels: ["MujerOBS","MujerRG","MujerRC" ],
    datasets: [      
      { label:'Avance',
      type: 'bar',
      data: dataBarM,  
      backgroundColor:'#C5236350',
      borderColor: '#C5236350',
      // fill: false                                      
    },     
      { label:'Mujer 20',
        type: 'line',
        data: dataM20,//[50, 40, 30, 50],
        backgroundColor:"#9ccdfa",    
        borderColor: '#9ccdfa',
        //fill: false,
        tension: 0.4   
      },  
      { label:'Mujer 40',
        type: 'line',
        data: dataM40, //[50, 40, 30, 50],
        backgroundColor:'#DBE772',
        borderColor: '#DBE772',
        //fill: false,
        tension: 0.4   
        
      },  
      { label:'Mujer 60',
        type: 'line',
        data: dataM60, //[50, 40, 30, 50],
        backgroundColor:'#119A4F',     
        borderColor: '#119A4F',
      //fill: false,                           
        tension: 0.4    
      },  
       
    ],
  }; 
  const options = {
  
  //  indexAxis:'y',
    tooltips: {
      enabled: true,
    },
   /*  layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
      }
    }, */
    title:{
      fontStyle: 'bold',
    },
    scales: {
      x: {
          display: true,
          title: {
          display: true
          }
      },
      y: {
          display: true,
          title: {
          display: false,
       
          },
       
      },
      }
   /*  scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        labe :{
          fontSize:6,
        },
      }
    
    }, */
  } 

  function  llenadoTabla2(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem',height:10+'rem',width:25+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={400} height={200} 
        options={options}
      />   
    </Card>
    );
  }

  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} mb={1} style={{marginTop:1+'rem'}} >           
     <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataGenero,process.env.REACT_APP_Promov):null}
      </Box>      
      <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataEdad,process.env.REACT_APP_Promov):null}
      </Box>     
     {/*  <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(Grafica3,process.env.REACT_APP_Promov):null}
      </Box>    */}  
  </Box>
  );
}