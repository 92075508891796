import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import {GeneralesContext} from '../context/GeneralesContext'
import { Box, Button, Card, CardContent, Grid,
    IconButton, LinearProgress, Paper, Tooltip,
    Typography, FormControl, InputLabel, Select,MenuItem,
    TextField} from "@material-ui/core";
import TablaRecorridos from "./TablaRecorridos";
import Relojes from "./Relojes";
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";
import {AddCircleOutlined} from "@material-ui/icons";
import ModalAgregarRecorrido from "./ModNvoEditRecorrido";
import ModalAgregarEvidencia from "./ModalAgregarEvidencia";
import { obtenDispo} from '../funciones/datosLocal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const useStyles = makeStyles((theme) => ({}));

const Recorrido = () => {
    const classes = useStyles();
    const auxDispV =obtenDispo()
    const idusu = localStorage.getItem("UsuId");
    const history = useHistory();
    const source = axios.CancelToken.source();

    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({
        inicio: new Date(),
        final: new Date(),
    });
    const {generales, guardarGenerales} = useContext(GeneralesContext);
    const [selectedID, setSelectedID] = useState(null);
    const [recorrido, setRecorrido] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [latitud, setLatitud] = useState(0);
    const [longitud, setLongitud] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [abrirModalMapa, setAbrirModalMapa] = useState(false);
    const [abrirModalAgregar, setAbrirModalAgregar] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [buscar, setBuscar] = useState(true);
    const [checked, setChecked] = useState(false);
    const [abrirModal, setAbrirModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [listSecc, setListSecc] = useState([])
    const [filtro, setFiltro] = useState({Seccion:"",direccion:"", observacion:""})
    const {Seccion,direccion, observacion}=filtro
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitud(position.coords.latitude);
                setLongitud(position.coords.longitude);
            });
        } else {
           // console.log("Not Available");
            setLatitud(0);
            setLongitud(0);
        }
        getSecciones()
        guardarGenerales({...generales, Titulo: 'Recorridos'})
        return ()=> {
            source.cancel();
        }  
    }, []);


    useEffect(() => {
        setGaleria([]);

        const consultarImagenes = () => {
            let data = qs.stringify({
                Llave: selectedID,
                Usuario: idusu,
                idDispositivo: "987gyubjhkkjh",
            });
            let url = "recorrido-file-list";

            function respuesta(response) {
                if (response[0].Id === -1) {
                    setGaleria([]);
                    setMensaje("Sin fotografías.");
                } else {
                    setGaleria(response);
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
        };
        if (selectedID !== null) {
            consultarImagenes();
        }
    }, [selectedID,update]);

    const getSecciones = () => {
        const data = qs.stringify({
            usuario: idusu, idDispositivo:auxDispV,
        });
        const url = "lugar-seccion-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
             
                const seccFilt = response.filter((auxSeccion) => auxSeccion.MuniId === 58)                    
                setListSecc(seccFilt)                              
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const onChangeSeccion = (e) => {
        if (e !== null && e !== undefined) {
            setFiltro({ ...filtro, Seccion: e.target.value })
         
        } else {
            setFiltro({ ...filtro, Seccion: "" })
        }
    }


    const onChange = e => {
        setFiltro({
            ...filtro,
            [e.target.name]: e.target.value
        })
    }

    const buscarDatos = () => {
        setBuscar(!buscar)
    }

    const onClickLimpiar = () => {
        setFiltro({Seccion:"",direccion:"", observacion:""})
    }


    const modalAgregarEvidencia = abrirModal ? <ModalAgregarEvidencia abrirModal={abrirModal}
                                                setAbrirModal={setAbrirModal} recorrido={recorrido}
                                                selectedID={selectedID} idusu={idusu}  setUpdate={setUpdate}
                                                
                                            /> : null

    const modalAgregarRecorrido = abrirModalAgregar ? <ModalAgregarRecorrido abrirModal={abrirModalAgregar}
                                                        setAbrirModal={setAbrirModalAgregar} latitud={latitud}
                                                        longitud={longitud} actualiza={buscarDatos}
                                                        titulo={'Nuevo Registro'}
                                                    /> : null

    const agregarDatos = () => {
        setAbrirModalAgregar(true)
    }

    const listarGaleria =
        galeria.length > 0 ? (
            <Galeria
                galeria={galeria}
            />
        ) : (
            <Paper className={classes.root}>
                <Typography variant={"h5"}>{mensaje}</Typography>
            </Paper>
        );

    const handleChange = () => {
        setChecked(!checked)
    };

    const handleAbrir = () => {
        setAbrirModal(true)
    }

    const principal = () => {
        return (
        <Grid container spacing={1}>
            {modalAgregarRecorrido}
            <Grid item xs={12}>
                <Card>
                    <CardContent style={{padding:0.4+'rem'}} >
                        <Grid container spacing={1} alignItems={'center'}>
                            <Grid item>
                                <Tooltip title={'Buscar'}>
                                    <IconButton color="primary" aria-label="Buscar"
                                        onClick={buscarDatos} >
                                        <SearchIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={'Limpiar Filtro'}>
                                    <IconButton color="primary"  aria-label="Limpiar Filtro"
                                        onClick={onClickLimpiar}
                                    >
                                        <HighlightOffIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Relojes
                                    fechasSeleccionadas={fechasSeleccionadas}
                                    setFechasSeleccionadas={setFechasSeleccionadas}
                                />
                            </Grid>
                            {/* <Grid item>
                                <FormControlLabel
                                    value={checked}
                                    control={<Checkbox color="primary"/>}
                                    label="Buscar solo mis registros"
                                    labelPlacement="end"
                                    size={'small'}
                                    onChange={handleChange}
                                />
                            </Grid> */}
                            {listSecc.length!==0?
                                <Grid>
                                <FormControl size='small'>
                                <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                                <Select size='small' variant="outlined" style={{width: '7rem', marginLeft:".5rem"}}
                                    label="Sección" id="Seccion-select2" name="Seccion"
                                    value={Seccion} onChange={onChangeSeccion}
                                    fullWidth
                                >
                                    {listSecc.map((seccion, ) => {
                                        return (
                                        <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                            <em>{seccion.Numero}</em>
                                        </MenuItem>
                                        )
                                        }
                                    )}
                                </Select>
                                </FormControl>
                                </Grid>
                            :null}
                            <Grid>
                                <TextField id="Direccion-txt" name="direccion" label="Dirección" 
                                    style={{width:"12rem", marginLeft:".5rem"}}  size="small"
                                    value={direccion} onChange={onChange} variant="outlined"/>
                            </Grid>
                            <Grid>
                                <TextField id="obsr-txt" name="observacion" label="Observación" 
                                    style={{width:"12rem", marginLeft:".5rem"}}  size="small"
                                    value={observacion} onChange={onChange} variant="outlined"/>
                            </Grid>
                                <Grid item>
                                <Tooltip title={'Agregar Recorrido'}>
                                    <IconButton
                                        color="primary"
                                        aria-label="Agregar Recorrido"
                                        onClick={agregarDatos}
                                    >
                                        <AddCircleOutlined/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8}>
                <TablaRecorridos
                    history={history} setRecorrido={setRecorrido}
                    fechasSeleccionadas={fechasSeleccionadas}
                    selectedID={selectedID} setSelectedID={setSelectedID}
                    source={source} buscar={buscar}
                    checked={checked} abrirModal={abrirModalMapa}
                    setAbrirModal={setAbrirModalMapa}
                    buscarDatos={buscarDatos} filtro={filtro}
                />
            </Grid>
            <Grid item xs={4}>
                {modalAgregarEvidencia}
                {/* recorrido.Obsrv ? 
                    cargando ? (
                        <Box mt={2} mb={2} width="80%">
                            <LinearProgress/>
                        </Box>
                    ) 
                    : (
                        <Grid container>
                        <Grid item xs={12} style={{paddingBottom: 10}}>
                            <Typography color="primary">
                                Observación
                            </Typography>
                            <Typography>
                                {recorrido?.Obsrv}
                            </Typography>
                        </Grid>
                        </Grid>
                    ) 
                :null */}
                {selectedID !== null ? <Grid container>
                    <Grid item xs={12} style={{paddingBottom: 10}}>
                        <Button
                            variant="contained" color="primary"
                            startIcon={<AddCircleOutlined/>}
                            onClick={handleAbrir}
                        >
                            Subir Fotos
                        </Button>
                    </Grid>
                </Grid> : null}
                {cargando ? (
                    <Box pl={1} pt={5}>
                        <LinearProgress/>
                    </Box>
                ) : (
                    listarGaleria
                )}
            </Grid>
        </Grid>
        );
    };

    return <div>{principal()}</div>;
};

export default Recorrido;
