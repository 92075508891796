export function obtenDispo() {
  return  localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 
        ? localStorage.getItem('Dispvo'):'w8rf51v21dsd2cs'             
}

export function obtenIdUsu() {
  return  localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')):0           
}

export function obtenIdUsuVerif() {
  return  localStorage.getItem('UsuId')            
}