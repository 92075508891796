export const tipoIcReco=(tipo)=>{
    let icono="ic_recor"
    switch (tipo) {
      case 1:
        icono=`${icono}/reco_barda`
        break;
      case 2:
        icono=`${icono}/reco_lona`
        break;
      case 3:
        icono=`${icono}/reco_especial`
        break;
      default:
      icono=`${icono}/general`
        break;
    }
    icono=`${icono}.svg`
    return icono
  }

export const infoReco=(info,auxCoord,valor,setLeyendaMap)=>{    
    let auxArr=[]
    const txt=`Usuario: ${info.UsrNom}`
    const txt2=`Tel: ${info.UsrTel}`
    const txt3=`Secc:${info.SeccNum}  Tipo: ${info.TipoNom}`       
    const txt4=`Observ: ${info.Obsrv}`       
    const txt5="Dir: "+info.Dir                      
    auxArr={id:info.Id, coordenada:auxCoord, texto:txt, texto2:txt2,
        texto3:txt3, texto4:txt4, texto5:txt5, valor:valor}        
    setLeyendaMap(auxArr)    
}
