import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField,
    RadioGroup,Radio,FormControlLabel,FormLabel } from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({}));

const ModalNvoProspecto = ({abrirModal, setAbrirModal, idusu, history, source, latitud, longitud, buscarDatos, setModalNvoImg, setNvoRegis}) => {
    let fechaMax = moment(new Date()).subtract(18,'years')    
    const classes = useStyles();
    let usuTipo = localStorage.getItem('Tipo');
    let fecha = Date.now()
    const [selectedDate, handleDateChange] = useState(fechaMax);
    const [listMun, setListMun] = useState([])
    const [listSecc, setListSecc] = useState([])
    const [auxListSecc, setAuxListSecc] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [nuevoRegistro, setNuevoRegistro] = useState({
        Estado: {Id: process.env.REACT_APP_EDO, Nom: "Puebla"},
        Municipio: '',
        Seccion: '',
        Tipo: '',
        Latitud: latitud,
        Longitud: longitud,
        Fecha: '',
        Direccion: '',
        Observacion: '',
        Ine:"",
        Curp:"",
        genero:"H",
        Nombre:"",
        Paterno:"",
        Materno:"",        
        Cel:"",
        Tel:"",
        Email:"",
        Promovido:"0",
        idDispositivo: '45vf2d1vdf21d2f'
    });    
    let Dispvo = localStorage.getItem("Dispvo");
    let auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);
    const [listEdo,setListEdo] = useState([])
    const {Estado, Municipio, Seccion, Tipo, Longitud, Latitud, Fecha, Direccion, Ine,
        Curp, genero, Nombre, Paterno, Materno,Cel, Tel, Email,Promovido} = nuevoRegistro
    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {
        const llamadaListEdo = () => {
            let data = qs.stringify({
                usuario: idusu,  idDispositivo: auxDispV,
            });
            let url = "lugar-estado";
    
            function respuesta(auxiliar) {
                if (auxiliar[0].Id != -1) {                    
                    return setListEdo(auxiliar)
                }
            }    
            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
        }

        

        const recuperarTipo = () => {
            let data = qs.stringify({
                Usuario: idusu,
                idDispositivo: auxDispV
            });
            let url = "prospecto-tipo";

            function respuesta(response) {
                if (response[0].Id != -1) {
                    setListTipo(response)
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
        };
        if (listEdo.length === 0) {
            llamadaListEdo()
        }
        if (listMun.length === 0) {
            recuperarMunicipios(Estado.Id)
        }
        if (listSecc.length === 0) {
            recuperarSecciones(Estado.Id)
        }
        if (listSecc.length === 0) {
            recuperarTipo()
        }
    }, []);

    const recuperarSecciones = (auxEdo) => {
        setListSecc([])
        let data = qs.stringify({
            usuario: idusu,
            estado: auxEdo,
            idDispositivo: auxDispV,
        });
        let url = "lugar-seccion-edo-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListSecc(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const recuperarMunicipios = (auxEdo) => {
        setListMun([])
        let data = qs.stringify({
            usuario: idusu,
            estado: auxEdo,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        let url = "lugar-municipio";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListMun(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarEdo = (e) => {

        if (e !== null && e !== undefined) {                        
            recuperarMunicipios(e.Id)
            recuperarSecciones(e.Id)
            setNuevoRegistro({
                ...nuevoRegistro,Estado: e,

                Municipio: "", Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,Estado: "",
                Municipio: "", Seccion: ""
            })
            setAuxListSecc([])
        }
    }

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {
            let seccFilt = listSecc.filter((auxSeccion) => auxSeccion.MuniId === e.Id)
            setAuxListSecc(seccFilt)
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: e, Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: "", Seccion: ""
            })
            setAuxListSecc([])
        }
    }

    const guardarSeccionSelect = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: e.target.value
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: ""
            })
        }
    }

    const onChange = e => {
        setNuevoRegistro({
            ...nuevoRegistro,
            [e.target.name]: e.target.value
        })
    }

    const guardarProspecto = () => {        
       // setBotonDeshabilitado(true);
        let data = qs.stringify({
            Usuario: idusu,
            Tipo: Tipo,
            Status:1,
            Estado: Estado.Id,
            Municipio: Municipio.Id,
            Seccion: Seccion,
            Perfil:0,            
            Latitud: Latitud,
            Longitud: Longitud,
            Nacimiento:moment.utc(selectedDate).format("YYYY-MM-DD") + 'T00:00:00',
            Ine:Ine,
            Curp:Curp,
            Sexo:genero,
            Nombre:Nombre,
            Paterno:Paterno,
            Materno:Materno,
            Domicilio: Direccion,            
            Cel:Cel,
            Tel:Tel,
            Email:Email,
            Promovido:Promovido,
            idDispositivo: '45vf2d1vdf21d2f'
        });

        let url = "prospecto-nuevo-1-1";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Militante agregado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-prospecto${1}`
                })
                buscarDatos()
                if (response.perfil!==0) {                    
                    setTimeout(() => {
                        //console.log('This will run after 1 second!')       
                        setModalNvoImg(true)
                      }, 500); 
                    setNvoRegis({llave:response.perfil, Nom: response.nombre})
                }else{
                    toast.error('Perfil ya creado', {
                        pauseOnFocusLoss: false,
                        toastId: `error-prospecto${1}`
                    })
                }
                setAbrirModal(false)
            } else {
               // setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-prospecto${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
           // console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const verificaTam = (campo,descripcion,tamanio)=>{
        let respuesta =false;        
          
        if(  !verificaNulos(campo,descripcion) && campo.length<tamanio  ) 
        {   respuesta=true;                                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    function guardarDatos(e) {
        e.preventDefault()
        if ( 
            !verificaNulos(Municipio,"Municipio") && !verificaNulos(Seccion,"Sección")  && 
            !verificaNulos(Tipo,"Tipo")&& 
            ((!verificaNulos(Nombre,"Nombre") && Nombre.length>=3 )|| !verificaNulos("","Nombre")) &&
            ((!verificaNulos(Paterno,"Apellido Paterno") && Paterno.length>=3 )|| !verificaNulos("","Apellido Paterno")) &&
            ((!verificaNulos(Materno,"Apellido Materno") && Materno.length>=3 )|| !verificaNulos("","Apellido Materno")) &&
            ((!verificaNulos(Ine,"INE") && Ine.length===18 )|| !verificaNulos("","INE"))              
        )            
        {   //console.log("prueba",nuevoRegistro);
            setAviso(false);
            guardarProspecto()
            
        }  
    }
    
    const onChangeGen = e =>
    {  
        setNuevoRegistro({ ...nuevoRegistro,          
            genero: e.target.value  
        })        
    };

    const onChangeProm = e =>
    {  
        setNuevoRegistro({ ...nuevoRegistro,          
            Promovido: e.target.value  
        })        
    };

    return (
    <Modal
        titulo={'Nuevo Registro'} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={5}>             
                <Autocomplete
                    id="edo-select-01" size='small' fullWidth //disabled
                    onChange={(e, option) => guardarEdo(option)} value={Estado}
                    getOptionLabel={(option) => option.Nom} name="Estado"
                    noOptionsText={"Sin coincidencias"} options={listEdo}
                    renderInput={(params) => (
                        <TextField {...params} size='small'
                            label="Estado" variant="outlined"/>
                    )}
                />
            </Grid>
            <Grid item xs={7}>
                {listMun.length !== 0 ?
                    <Autocomplete
                        id="muni-select-nvoPerfil" size='small' fullWidth
                        onChange={(e, option) => guardarMuni(option)} value={Municipio}
                        getOptionLabel={(option) => option.Nom} name="Municipio"
                        noOptionsText={"Sin coincidencias"} options={listMun}
                        renderInput={(params) => (
                            <TextField {...params} size='small'
                                       label="Municipio" variant="outlined"/>
                        )}
                    />
                    : <LinearProgress/>}
            </Grid>
            <Grid item xs={3}>
                {auxListSecc.length !== 0 ?
                    <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}}
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            {auxListSecc.map((seccion, index) => {
                                return (
                                <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                    <em>{seccion.Numero}</em>
                                </MenuItem>
                                )
                                }
                            )}
                        </Select>
                    </FormControl>
                    : <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}} 
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            <MenuItem size='small' value={''}>
                                <em></em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            </Grid>
            <Grid item xs={3}>
                {listTipo.length !== 0 ?
                <FormControl size="small">
                    <InputLabel variant="outlined">Tipo</InputLabel>
                    <Select  
                        size='small' variant="outlined" style={{minWidth: '7rem'}}
                        label="Tipo" id="Tipo-select2" name="Tipo"
                        value={Tipo} onChange={onChange}
                        fullWidth
                    >
                        {listTipo.map(element => {                            
                            return(
                            <MenuItem size='small' value={element.Id} key={element.Id}>
                           {element.Nom} 
                        </MenuItem>)                             
                        })}
                    </Select>
                </FormControl>
                : null
                }
            </Grid>            
            <Grid item xs={3}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture label="Fecha Nacimiento"
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate="1900-03-14" maxDate={fechaMax}
                        value={selectedDate} onChange={handleDateChange}
                        //style={{width: 8.5 + 'rem'}}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
            <FormControl component="fieldset" style={{marginLeft:.5+'rem'}}>          
                <FormLabel  htmlFor="Sexo">Sexo</FormLabel>        
                <RadioGroup  row aria-label="position"  defaultValue="top"
                    name="genero" value={genero} onChange={onChangeGen}
                >
                    <FormControlLabel value='H'  
                        control={<Radio color="primary" />} label="H" />    
                    <FormControlLabel value='M'   
                        control={<Radio color="primary" />} label="M" />                         
                </RadioGroup>                     
            </FormControl>      
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="Nombre" name="Nombre" label="Nombre" fullWidth size="small"
                    value={Nombre} onChange={onChange} variant="outlined"/>                
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Paterno" name="Paterno" label="Apellido Paterno" fullWidth size="small"
                    value={Paterno} onChange={onChange} variant="outlined"/>                
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Materno" name="Materno" label="Apellido Materno" fullWidth size="small"
                    value={Materno} onChange={onChange} variant="outlined"/>
            </Grid>
            
            <Grid item xs={6} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Ine-text" name="Ine" label="Clave de Elector" fullWidth size="small"
                    value={Ine} onChange={onChange} variant="outlined" inputProps= {{ maxlength: 18 }} />
            </Grid>
            <Grid item xs={6} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Curp-text" name="Curp" label="CURP" fullWidth size="small"
                    value={Curp} onChange={onChange} variant="outlined" inputProps= {{ maxlength: 18 }} />
            </Grid>            
            <Grid item xs={12} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="Direccion" name="Direccion" label="Dirección" fullWidth size="small"
                    value={Direccion} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={3} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Cel-text" name="Cel" label="Celular" fullWidth size="small"
                    value={Cel} onChange={onChange} variant="outlined" inputProps= {{ maxlength: 10 }}/>
            </Grid>
            <Grid item xs={3} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Tel-text" name="Tel" label="Teléfono" fullWidth size="small"
                    value={Tel} onChange={onChange} variant="outlined" inputProps= {{ maxlength: 10 }}/>
            </Grid>
            <Grid item xs={6} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>                
                <TextField id="Email-text" name="Email" label="Email" fullWidth size="small"
                    value={Email} onChange={onChange} variant="outlined"/>
            </Grid>
            {/* <Grid item xs={12}>
                <TextField
                    id="Observacion" name="Observacion"
                    label="Observación" variant="outlined"
                    multiline fullWidth onChange={onChange}
                    rows={4} value={Observacion}
                />
            </Grid> */}
            <Grid item xs={5}>
            <FormControl component="fieldset" style={{marginLeft:.5+'rem'}}>          
                <FormLabel  htmlFor="Promovido">¿Generar promovido?</FormLabel>        
                <RadioGroup  row aria-label="position"  defaultValue="top"
                    name="Promovido" value={Promovido} onChange={onChangeProm}
                >
                    <FormControlLabel value='0'  
                        control={<Radio color="primary" />} label="No" />    
                    <FormControlLabel value='1'   
                        control={<Radio color="primary" />} label="Si" />                         
                </RadioGroup>                     
            </FormControl>      
            </Grid>
        </Grid>
    </Modal>);
};

export default ModalNvoProspecto;
