import React,{useState,useEffect} from 'react'
import {Box, TextField,FormControl, Tooltip,LinearProgress,
        Select,MenuItem,InputLabel, Card,Typography,FormLabel,
        RadioGroup,Radio,FormControlLabel} from '@material-ui/core' 
import DateFnsUtils from '@date-io/date-fns'; 
import esLocale from "date-fns/locale/es";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
import moment from 'moment'
import MapaAlerta from '../generales/MapaAlerta'
import {llamaApiCargaToken,ErrorGeneral,llamadaApiCarga } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 

const listAfil=[{Id:0,desc:"Observador"},{Id:1,desc:"RG"},{Id:2,desc:"RC"}]

const ModalNvoEdit = ({modalAbierto,setModalAbierto ,titulo,listMun,listSecc,listPart,auxReg,getDatos,listSeccDto,llamaListSeccDto}) => {
    let history = useHistory();    
    const fechaMax = moment(new Date()).subtract(18,'years')  
    const nvl= localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;   
    const usuId =localStorage.getItem('UsuId') ;                
    const usuDto =localStorage.getItem('UsuDto') ;
    const usuMun =localStorage.getItem('UsuMun') ? parseInt(localStorage.getItem('UsuMun') ) :0;
    const usuSec =localStorage.getItem('UsuSec') ?parseInt(localStorage.getItem('UsuSec') ):0;
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);
    const[auxListSecc,setAuxListSecc]= useState([]);    
    const[posicion,setPosicion]= useState([])
    const[mensajePos,setMensajePos]= useState("")
    const[cambioEfectivo,serCambioEfectivo] = useState(false)    
    const[nvoRegistro, setNvoRegistro] = useState([])   
    const[validaPerfil, setValidaPerfil] = useState({perfil:false,nombre:false})  
    const[registro, setRegistro] = useState({Nombre:'', FNacimien:fechaMax, Email:'' ,
        Tel:'',ine:"",Municipio:"",Seccion:'',genero:"H",
        Llave:0,Tipo:"0",Edad:0,ApPat:"",ApMat:"",Participo:"0",Partido:null})  
    const{Llave,  Nombre,FNacimien,Email,Tel,ine,Municipio,Seccion, Tipo, Edad, 
            ApPat, ApMat, Participo,Partido,genero}=registro
    const[arrError,setArrError] = useState([false,false,false,false,false,false])     
    const source = axios.CancelToken.source();  
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    
    useEffect(()=>{    
        if (!auxReg) 
        {             
            getLocation()
        }
        return ()=> {
            source.cancel();
        } 
    },[])

    useEffect(() => {
      
        if (auxReg) 
        {  // console.log(auxReg);
            const auxMun=listMun.filter((auxM)=>auxM.Id===auxReg.MunId)   
            const auxTipo=auxReg.Tipo ==="NO"|| auxReg.Tipo ==="OTRO" ?"Observador":auxReg.Tipo
            const filtrado=listAfil.filter((afil)=>afil.desc===auxTipo)            
            setRegistro({...registro,Llave:auxReg.Id, Nombre:auxReg.Nom,
                FNacimien:`${moment.utc(auxReg.FNac).format('YYYY-MM-DD')}T12:00`, Email:auxReg.Email,Tel:auxReg.Tel, ine:auxReg.Ine,
                Municipio:auxMun[0], Seccion:auxReg.SecId, Tipo:filtrado[0].length!==0?`${filtrado[0].Id}`:"0" , 
                Edad:auxReg.Edad, ApPat:auxReg.Pat, ApMat:auxReg.Mat, genero: auxReg.Genero,
                Participo:`${auxReg.Participo}`,Partido:auxReg.PartId})
            const seccFilt=listSecc.filter((auxSeccion)=>auxSeccion.MuniId===( 40))             
            setAuxListSecc(seccFilt)    
            setPosicion({Lat:auxReg.Lat,Lon:auxReg.Lon})
        }else{
         let auxMun=[]
            
            if (usuMun!==0) {
                auxMun=listMun.filter((auxM)=>auxM.Id===usuMun)      
            }                      
            
            if (auxMun.length !==0  ) {
                const seccFilt=listSecc.filter((auxSeccion)=>auxSeccion.MuniId===auxMun[0].Id) 
                console.log(seccFilt);
                setAuxListSecc(seccFilt)    
            }
            if (nvl===9&& usuMun!==0) 
            {
                llamaListSeccDto(usuMun)    
            }
            setRegistro({...registro,                
                Municipio:auxMun.length!==0 ?auxMun[0]:"", Seccion:usuSec===0?"":usuSec,                 
            }) 
        }
        
      
    }, [])
    
    const guardarFNaci = auxfecha =>
    {   
        setRegistro({
            ...registro,
            FNacimien : auxfecha  
        })
    };

    function getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } 
        else 
        {  
            setMensajePos( "La Geolocalizacion no es soportada por tu navegador.");
            setPosicion([])
        }
    }
      
    function showPosition(position) {
        let Latitude= position.coords.latitude ;
        let Longitude= position.coords.longitude;        
        setPosicion({Lat:Latitude,Lon:Longitude})
    }

    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
        <Tooltip title="Seleccionar Fecha Nacimiento">
          <Box  mr={1}  component="span" width={1/4}>           
            <DatePicker style={{width:8.3+'rem'}}
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"    
              minDate="1900-03-14" maxDate={fechaMax}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
        </Tooltip>                                             
        </MuiPickersUtilsProvider>
    }

    const onChange = e =>
	{   
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setRegistro({
                ...registro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};             

    function respuestaDuplicado(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0
        let auxArr=arrError
        if (isEmpty || auxiliar.rechazo===0 ||  auxiliar.rechazo==="0"   ) 
        {   //             
            auxArr[3]=true     
            setArrError(auxArr)                 
            let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
            llamadaNvoPerfil(auxFechaNacimient+'T00:00:00')              
        } 
        else 
        {   //let mensaje = "Nombre ya registrado, ingrese un Nombre diferente.";
            let mensaje = auxiliar.mensaje//"Nombre ya registrado, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${6}`
            })                                    
            mandaAlerta(mensaje, "warning");
            auxArr=arrError
            auxArr[3]=true                    
            setArrError(auxArr) 
            setBtnDeshabilitado(false)               
        }
    }

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setRegistro({
                ...registro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
         
        }		    

	}; 
 
    const llamadaNvoPerfil = (auxFechaNacimient,anios) => {                    
        const data = qs.stringify({
            Usuario:usuId,
            Llave:Llave,
            Tipo:Tipo,
            Muni:Municipio.Id,
            Secc:Seccion,
            Edad:anios,
            FNac:auxFechaNacimient ,
            Ine:ine,
            Nombre:Nombre,
            Paterno:ApPat,
            Materno:ApMat,
            Tel:Tel,
            Email:Email,
            genero:genero,
            Latitud:posicion.length > 0 ? posicion.Lat : 0,
            Longitud:posicion.length > 0 ? posicion.Lon : 0,
            Participo:Participo,
            Partido:Partido?Partido:0,
            idDispositivo: auxDispV,
        });
        const url = "estructura-nuevo";
        function respuesta(aux) {
            if (aux.length!==0)
            {   setBtnDeshabilitado(true)                                             
                const mensaje=auxReg ?"Se edito correctamente":"Se registro correctamente"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);                
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-afil${1}`
                })          
                getDatos(auxReg===1? 1:2)      
                setModalAbierto(false);
                setNvoRegistro(aux)
                serCambioEfectivo(true) 
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

    const onChangeCorreo = e =>
	{   let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setRegistro({
                    ...registro, 
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;                  
        if( campo===null || (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                   
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }     

    const verificaNulos2 = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                   
            setMensaje('Debe llenarse el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }    

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
 
    function guardarDatos(e)
    {   e.preventDefault()                
        //serCambioEfectivo(true)          
        const auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
        if (!verificaNulos(Municipio,"Municipio") &&!verificaNulos(Seccion,"Sección")  && validaVariable(Nombre,'Nombre',3)             
            && validaVariable(ApPat,'Apellido Paterno',3)&& validaVariable(ApMat,'Apellido Materno',3)    //  && validaVariable(Tel,'Telefono',10)
            && (Tipo ==="0" || (Tipo !=="0"   && validaVariable(Tel,'Telefono',10)//  && validaVariable(ine,'INE',18)
                && (Participo ==="0" || (Participo ==="1" && !verificaNulos(Partido,"Partido") ))))
          )
        {           
            setMensaje('');                
            setAviso(false);                
            //  setBtnDeshabilitado(true)               
            const anios = moment().diff(auxFechaNacimient, 'years',false);                  
               
            llamadaNvoPerfil(auxFechaNacimient+'T00:00:00', anios)                               
        }                                  
    } 
      
    const guardarMuni=(e)=>{    
        if (e!== null && e!== undefined) 
        {
            //console.log(e.Id);
            if (nvl===9) 
            {
                llamaListSeccDto(e.Id)    
            }else{
                let seccFilt=listSecc.filter((auxSeccion)=>auxSeccion.MuniId===e.Id)                         
                setAuxListSecc(seccFilt)    
            }
            
            setRegistro({
                ...registro,
                Municipio : e  ,  
                Seccion :""  
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        {   setRegistro({
                ...registro,
                Municipio : "" ,Seccion :""   
            })           
            setAuxListSecc([])  
        }        
    }

    const guardarSeccionSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setRegistro({
                ...registro,
                Seccion :e.target.value 
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        { setRegistro({
            ...registro,
            Seccion : ""  
        })           
        }        
    }  

    const onChangeSel=(e)=>{
        if (e!== null && e!== undefined) 
        {  setRegistro({...registro,[e.target.name] : e.target.value})
        }else
        {setRegistro({...registro,[e.target.name] : 0})
        } 
        
    }

    const onChangeRad=(e)=>{
        if (e.target.name==="Tipo") 
        {   setRegistro({...registro,[e.target.name] : e.target.value,
                Participo:"0",Partido:null
            })            
        }
        else
        {   if (e.target.name==="Participo") 
            {   setRegistro({...registro,[e.target.name] : e.target.value,
                    Partido:null
                })            
            }
            else
            {   setRegistro({...registro,[e.target.name] : e.target.value,                    
                })           
            }
            
        }                
    }

    const dtoSecc=()=>{
        return listSeccDto.length !== 0 ?
            <FormControl size='small' style={{marginLeft:1.2+'rem' }}>
                <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                <Select size='small' variant="outlined" style={{width: '7rem'}}
                    label="Sección" id="Seccion-select2" name="Seccion"
                    value={Seccion} onChange={guardarSeccionSelect}
                    fullWidth  disabled={nvl===8 ?true:false}        
                >
                    {listSeccDto.map((seccion, index) => {
                        return (
                        <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                            <em>{seccion.NUm}</em>
                        </MenuItem>
                        )
                        }
                    )}
                </Select>
            </FormControl>
            :null         
    }
    const DatosGenerales=(
        <div>            
           <Box   pt={1} mb={1} display="flex" flexDirection={"row"}>
                {listMun.length!==0 ?
                <Autocomplete
                    id="muni-select-nvoPerfil" size='small' style={{ width: 20.7+'rem' }}
                    onChange={(e,option)=>guardarMuni(option)}  value={Municipio}      
                    getOptionLabel={(option) => option.Nom} name="Municipio" disabled={nvl===10||nvl===8 ?true:false}        
                    noOptionsText={"Sin coincidencias"} options={listMun}
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Municipio" variant="outlined" />
                    )} 
                />
                :<LinearProgress/>}
                {nvl!==10 && nvl!==9 && auxListSecc.length!==0 ?                
                <Box display={"flex"} mb={1}>
                <FormControl size='small'  style={{marginLeft:1.6+'rem', minWidth:8.5+'rem'}} >
                    <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                    <Select  size='small' variant="outlined" disabled={nvl===8 ?true:false}        
                        label="Sección" id="Seccion-select2" name="Seccion"
                        value={Seccion} onChange={guardarSeccionSelect}
                    >
                        {auxListSecc.map((secc, index) => {
                            if(secc.Id>0) {
                            return(
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Numero }</em>
                            </MenuItem>
                            )}
                        } )}                    
                    </Select>
                    </FormControl>
                    </Box>
                :   dtoSecc()
                }
                 <FormControl component="fieldset" style={{marginLeft:.5+'rem' }}>          
                    <FormLabel  >Genero</FormLabel>        
                    <RadioGroup  row aria-label="position"  defaultValue="top"
                        name="genero" value={genero} onChange={onChangeRad}
                    >                        
                        <FormControlLabel value={"H"}  label={"H"}
                            style={{}}
                            control={<Radio color="primary" />} />
                        <FormControlLabel value={"M"}  label={"M"}
                            style={{}}
                            control={<Radio color="primary" />} />                                                                      
                    </RadioGroup>                     
                </FormControl>  
               
            </Box>               
            {RelojFNacimiento()}
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
            />
            <TextField required id="ApMat-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:10+'rem',marginRight:".5rem"}}
                label="Apellido Paterno" value={ApPat} name='ApPat'size="small" autoComplete='off'                 
            />
            <TextField required id="ApMat-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:10+'rem'}}
                label="Apellido Materno" value={ApMat} name='ApMat'size="small" autoComplete='off'                 
            />
            <br/>            
             <TextField  id="ine-input2" onChange={onChangeCorreo} style={{width:12+'rem',paddingRight:.8+'rem',paddingBottom:.5+'rem',}}
                label="Ine"  value={ine} name='ine' size="small" inputProps= {{ maxlength: 18}}
                autoComplete='off'
                />             
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:8+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'
            /> 
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:20+'rem'}}
                label="Email"  value={Email} name='Email' size="small" autoComplete='off'
            />  
            <br/>
            <Box display={"flex"} style={{marginBottom:"1rem"}} >
                <FormControl component="fieldset" style={{marginLeft:.5+'rem' }}>          
                    <FormLabel  >¿Como participara RG / RC?</FormLabel>        
                    <RadioGroup  row aria-label="position"  defaultValue="top"
                    name="Tipo" value={Tipo} onChange={onChangeRad}
                    >
                        {listAfil.map((elemnt)=>
                            <FormControlLabel value={elemnt.Id.toString()}  label={elemnt.desc}
                                style={{color:elemnt.Color}}
                                control={<Radio color="primary" />} /> 
                        )}                                                
                    </RadioGroup>                     
                </FormControl>  
               { Tipo !=="0" ?<FormControl component="fieldset" style={{marginLeft:.5+'rem' }}>          
                    <FormLabel  >¿Participó en elecciones anteriores?</FormLabel>        
                    <RadioGroup  row aria-label="position"  defaultValue="top"
                        name="Participo" value={Participo} onChange={onChangeRad}
                    >                        
                        <FormControlLabel value={"0"}  label={"No"}
                            style={{}}
                            control={<Radio color="primary" />} />
                        <FormControlLabel value={"1"}  label={"Si"}
                            style={{}}
                            control={<Radio color="primary" />} />                                                                      
                    </RadioGroup>                     
                </FormControl>  
                :null
                }
                {Tipo!=="0"&& Participo !=="0"&& listPart.length!==0 ?                                
                    <FormControl size='small'  style={{marginLeft:1.6+'rem', minWidth:8.5+'rem',marginTop:".3rem"}} >
                        <InputLabel size='small' variant="outlined" id="Partido-label-nvo">Partido</InputLabel>
                        <Select  size='small' variant="outlined"
                            label="Partido" id="Partido-select2" name="Partido"
                            value={Partido} onChange={onChangeSel}
                        >
                            {listPart.map((part, index) => {
                                if(part.Id>0) {
                                return(
                                <MenuItem size='small' value={part.Id} key={part.Id}>
                                    <em>{part.Nom }</em>
                                </MenuItem>
                                )}
                            } )}                    
                        </Select>
                    </FormControl>
                    
                    :   null
                    }
            </Box>
            <br/>
            {posicion.length!==0?
                <Box style={{width:30.8+'rem',height:12+'rem'}}>
                    <Card style={{display:'flex'}}>
                    <MapaAlerta Seleccionado={posicion} 
                        tamanio={{ height: "12rem", width: "100%"}}
                    />
                    </Card>
                </Box>
            :
                <Typography variant='h6'>Dirección no disponible.
                    {" "+ mensajePos?mensajePos:""}
                </Typography>
            }
        </div> 
    )     

    const cerrarBorrar = () => {            
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {   
        
        }
    };

    return (    
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}   
    >
        {DatosGenerales}
    </Modal>             
    )
}

export default ModalNvoEdit