import React,{useState,useEffect,useContext} from 'react';
import {Box,Typography,//IconButton,
    IconButton,Tooltip,FormControl,InputLabel,Select,
    MenuItem,TextField,FormControlLabel,Checkbox} from "@material-ui/core";
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import TablaMun from './TablaMun';
//import GraficaPolar from './GraficaPolar';
import IntervaloSelect from '../perfiles/IntervaloTabla';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import TablaAvUsr from './TablaAvUsr'
const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
"#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const AvancePerfilUsr = () => {
    const history = useHistory();
    const ColBase=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const auxId =localStorage.getItem('UsuId') ;  
    const nvl= localStorage.getItem('Tipo');          
    const usu= localStorage.getItem('UsuarioNom');   
    const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 ? localStorage.getItem('Dispvo') :'w8rf51v21dsd2cs'      
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);    
    const [listTotl, setListTotl] = useState([])                                  
    const [espera, setEspera] = useState(false)                                              
    const [fSelect, setFSelect] = useState({inicio:new Date("2023-01-01T00:00:00"),final:new Date()});
    const [buscar, setBuscar] = useState(false);
 

    const reiniciar=()=>{
 
        setListTotl([])                                                                         
        setBuscar(!buscar);
    }

    useEffect(()=>{             
        const autenticado = authUser();
        if(!autenticado)
        {   localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {   if (usuario.length===0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                           
            }                       
         
            guardarGenerales({...generales,Titulo:'Avance General'})      
            llenaTotales(1,0)            
        }
        
        return ()=> {
            source.cancel();
        }      
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[buscar]);


    const llenaTotales = () => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");         
        const data = qs.stringify({
            usuario: auxId,                              
            fini:fInicio+'T00:00:00',
            ffin:fFinal+'T23:59:59',
            idDispositivo: auxDispV,
        });
        const url = "perfil/v1/usr/avance/seccion";

        function respuesta(response) {
            if (response[0].Id !== -1) 
            {   
                setListTotl(response)                           
            }
        }
    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral,setEspera, history, source);
    };


    return (
    <div style={{display:"flex", flexDirection:"column"}}>
        
 
        <Box display={'flex'} pb={1}>
            <Box >
                <IconButton color="primary"  aria-label="add circle" 
                component="span" onClick={()=>setBuscar(!buscar)}>
                    <Tooltip title="Buscar">
                        <SearchIcon/>
                    </Tooltip>
                </IconButton>                                                                                                                              
            </Box>
            <Box >
                <IconButton color="primary"  aria-label="add circle" 
                component="span" onClick={reiniciar}>
                    <Tooltip title="Reiniciar">
                        <ReplayIcon/>
                    </Tooltip>
                </IconButton>                                                                                                                              
            </Box>
            <Box >
                <IntervaloSelect  fSelect={fSelect}  setFSelect={setFSelect}/>
            </Box>
        
        </Box>
 
        <Box display={"flex"} flexDirection={"row"} mt={1}>
        
            {   listTotl.length !== 0 ?
                <TablaAvUsr listUsr={listTotl}/>       
            :null}
            
        
        </Box>            
   
    </div>
  )
}

export default AvancePerfilUsr