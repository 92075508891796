import React,{useState,useEffect} from 'react'
import {Box, TextField,FormControl, Tooltip,LinearProgress,
        FormLabel, FormControlLabel, RadioGroup,
        Card, Typography, Radio, FormGroup, Checkbox,FormHelperText} from '@material-ui/core' 
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
import moment from 'moment'
import MapaAlerta from '../perfiles/MapaAlerta'
import {llamadaApiToken,ErrorGeneral,llamadaApiCarga,llamaApiCargaToken } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
import { obtenDispo } from '../funciones/datosLocal';
import SelectEdo from '../generales/SelectEdo';
import SelectList from '../generales/SelectList';

const ModalPerfilv2 = ({modalAbierto,setModalAbierto ,titulo,listPlantilla,setModalAvisoImg,setNvoRegisPerfil, listReferente}) => {
    let history = useHistory();      
    const auxDispV =obtenDispo() 
    const source = axios.CancelToken.source();   
    const usuId =localStorage.getItem('UsuId') ;        
    const fechaMax = moment("2005-06-30T00:00:00")   
    const[perfil,setPerfil]= useState({Nombre:'', FNacimien:fechaMax, Email:'@' ,calle:'',
                                                Tel:'',ine:"",Municipio:null,Seccion:null,plantilla:0,
                                                edo:null,referente:null, genero:"H",
                                            });      
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)
    const [aviso, setAviso] = useState(false);    
    const[posicion,setPosicion]= useState([])
    const[mensajePos,setMensajePos]= useState("")
    const[cambioEfectivo,serCambioEfectivo] = useState(false)    
    const[nvoRegistro, setNvoRegistro] = useState([])       
    const[arrError,setArrError] = useState([false,false,false,false,false,false])     
    const[listMun, setListMun] = useState([])   
    const[listSecc, setListSecc] = useState([])           
    const[pregunta, setPregunta] = useState({resp1:"",resp2:"",resp3:"",resp4:"",resp5:""})   
    const{resp1,resp2,resp3,resp4,resp5}=pregunta
    const{  Nombre, FNacimien, Email, calle, Tel, ine, Municipio, Seccion, plantilla, edo, referente, genero}=perfil

    useEffect(()=>{        
        setPerfil({...perfil,plantilla:listPlantilla[0].Id,
            edo: localStorage.getItem('prflEdo')??null,
            Seccion: localStorage.getItem('prflSecc')??null,
        })
        getLocation()       
    },[])   

    useEffect(() => {
        if (edo) {
            getListMuni(edo)
        }
               
    }, [edo])

    useEffect(() => {
        if (Municipio) {
            getListSecc(Municipio.Id)
        }
            
    }, [Municipio])

    function getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } 
        else 
        {   setMensajePos( "La Geolocalizacion no es soportada por tu navegador.");
            setPosicion([])
        }
    }
      
    function showPosition(position) {
        let Latitude= position.coords.latitude ;
        let Longitude= position.coords.longitude;
        //console.log("sin obtener la location");
        /* Geocode.fromLatLng(Latitude, Longitude).then(
            (response) => {
              const address = response.results[0].formatted_address;
              console.log(address);
            },
            (error) => {
              console.error(error);
            }
        ); */
       // console.log({lat:Latitude,Lng:Longitude});
        setPosicion({Lat:Latitude,Lon:Longitude})
    }

    function getListMuni(auxEdo)  {            
        const url= `lugar-municipio`;                            
        const data = qs.stringify({                
            usuario:usuId,              
            estado:auxEdo,                                                
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {   setListMun(aux) 
                filtroMun(aux)               
            }  
            else{
                setListMun([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const getListSecc = (auxMuni) => {
        const data = qs.stringify({
            usuario: usuId, municipio: auxMuni, idDispositivo: auxDispV,
        });
        const url = "lugar-seccion";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id != -1) {                
                setListSecc(auxiliar)

            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }

    const filtroMun=(aux)=>{
        if (localStorage.getItem('prflMuni')) 
        {
            const filtro=aux.filter((mun)=>mun.Id ===parseInt(localStorage.getItem('prflMuni'))) 
            setPerfil({...perfil,Municipio:filtro[0]??null})
        }else{
            setPerfil({...perfil,Municipio:null,Seccion:null})
        }       
    }

    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
        <Tooltip title="Seleccionar Fecha Nacimiento">
          <Box  mr={1}  component="span" width={1/4}>           
            <DatePicker style={{width:8.3+'rem'}}
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"    
              minDate="1900-03-14" maxDate={fechaMax}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
        </Tooltip>                                             
        </MuiPickersUtilsProvider>
    }

    const guardarFNaci = auxfecha =>
    {   
        setPerfil({ ...perfil,FNacimien : auxfecha  })
    };

    const onChange = e =>
	{   let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setPerfil({...perfil,[e.target.name] : e.target.value})            
        }		
	};             

    const verifiNombre = () => {                   
        let data = qs.stringify({
            cadena:Nombre.trim(),    
            idDispositivo: auxDispV,
        });
        let url = "perfil-verifica-cadena";
        llamadaApiCarga(data, url, respuestaNombre,ErrorGeneral,setEspera,history);
    };

    function respuestaNombre(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0                        
        let auxArr=arrError
        if (isEmpty || auxiliar.coincidencia===0 ||  auxiliar.coincidencia==="0"   ) 
        {                             
            auxArr[3]=false 
            setArrError(auxArr)
            //setAviso(false)   
            let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
            validaDuplicado(auxFechaNacimient)              
        } 
        else 
        {   let mensaje = "Nombre invalido, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${5}`
            })              
            mandaAlerta(mensaje, "warning");              
            auxArr[3]=true                                
            setArrError(auxArr)
            setBtnDeshabilitado(false)           
        }
         
    }

    const validaDuplicado = (fecha) => {              
        let data = qs.stringify({
            nombre:Nombre.trim(),
            nacimiento:fecha+"T00:00:00",
            municipio:Municipio.Id,
            idDispositivo: auxDispV,
        });
        let url = "perfil-verifica-duplicado";
        llamadaApiCarga(data, url, respuestaDuplicado,ErrorGeneral,setEspera,history);
    };

    function respuestaDuplicado(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0
        let auxArr=arrError
        if (isEmpty || auxiliar.rechazo===0 ||  auxiliar.rechazo==="0"   ) 
        {   //             
            auxArr[3]=true     
            setArrError(auxArr)     
            console.log(auxiliar);
            let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
            llamadaNvoPerfil(auxFechaNacimient+'T00:00:00')  
            //setBtnDeshabilitado(false)     
        } 
        else 
        {   //let mensaje = "Nombre ya registrado, ingrese un Nombre diferente.";
            let mensaje = auxiliar.mensaje//"Nombre ya registrado, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${6}`
            })                                    
            mandaAlerta(mensaje, "warning");
            auxArr=arrError
            auxArr[3]=true                    
            setArrError(auxArr) 
            setBtnDeshabilitado(false)               
        }
    }

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setPerfil({ ...perfil,[e.target.name] : e.target.value })            
            if (e.target.value.length===10) {
                setBtnDeshabilitado(false)                     
            }            
            else{
                setBtnDeshabilitado(true)                     
            }
        }		    

	}; 
     
    function llamadaNvoPerfil( auxFechaNacimient)  {               
        const url=`perfil/v3/perfil-new`;      
        const filtrado=listReferente.filter((refer)=> refer.Id===referente)                                                                                              
        const dataInfo = qs.stringify({                                                                               
            usuario:usuId,
            telefono:Tel,
            nombre:Nombre,            
            email:"@",
            direccion:calle,            
            version:'1.0.0',
            plantilla:filtrado[0].PlantId,
            nacimiento:auxFechaNacimient,
            ine:ine,
            municipio:Municipio.Id,
            seccion:Seccion,
            referente:referente??0,
            latitud:posicion.length > 0 ? posicion.Lat : 0,
            longitud:posicion.length > 0 ? posicion.Lon : 0,
            genero:genero,
            idDispositivo:auxDispV
            });

        function respuesta (aux) {                          
           // console.log(aux);                                                   
            if (aux?.respuesta!==0) 
            {                               
                setBtnDeshabilitado(true)                                             
                const mensaje="Se registro correctamente el Perfil"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);                
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-perfil${aux.mensaje}`
                })
                setNvoRegisPerfil({Nom:Nombre,llave:aux.mensaje, PlantId:filtrado[0].PlantId})
                setModalAbierto(false);
                setNvoRegistro(aux)
                serCambioEfectivo(true) 
                
            }                                            
        }     
        llamaApiCargaToken(dataInfo, url, respuesta, ErrorGeneral, setEspera, history, source);  
    };

    const onChangeCorreo = e =>
	{   let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setPerfil({ ...perfil, [e.target.name] : e.target.value })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if(campo===null|| (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
            //console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
  
    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
 
    const guardarMuni=(e)=>{    
        if (e!== null && e!== undefined) 
        {                            
            setPerfil({ ...perfil, Municipio : e  , Seccion :""  })      
            setMensaje('');                
            setAviso(false);             
            localStorage.setItem('prflMuni',e.Id)    
            localStorage.removeItem("prflSecc")   
        }else
        {   setPerfil({...perfil, Municipio : "" ,Seccion :""   })                       
        }        
    }

    const onchangeLocal=(e)=>{
        switch (e.target.name ) {
            case 'edo':                
                localStorage.setItem('prflEdo',e.target.value)     
                localStorage.removeItem("prflMuni")    
                localStorage.removeItem("prflSecc")     
                break;
            case 'Seccion':
                localStorage.setItem('prflSecc',e.target.value)        
                break;                                
            default:
                break;
        }        
    }

    const onChangeSlct=(e)=>{            
         if (e!== null && e!== undefined) 
        {   setPerfil({...perfil,[e.target.name]:e.target.value})   
            onchangeLocal(e)
        }
        else
        {   setPerfil({...perfil,[e.target.name]:""})          
        }        
    }

    const onChangeGen = e =>
    {  
        setPerfil({ ...perfil,genero: e.target.value  })        
    };
    
    const onCheck = (event) => {
        setPregunta({ ...pregunta, [event.target.name]: event.target.checked });
      };

    function guardarDatos(e)
    {   e.preventDefault()                
        if (!verificaNulos(referente,"Referente") &&!verificaNulos(Municipio,"Municipio") &&!verificaNulos(Seccion,"Sección")  
            && validaVariable(Nombre,'Nombre',7) && validaVariable(Tel,'Telefono',10))
        {           
            setMensaje('');                
            setAviso(false);               
            setBtnDeshabilitado(true)   
            verifiNombre()                  
            //llamadaNvoPerfil(auxFechaNacimient+'T00:00:00')        
        }                                  
    } 
      
    const DatosGenerales=(
        <div>
            {listReferente.length!==0 ?                
                <Box display={"flex"} mb={2} >
                     <SelectList lista={listReferente} value={referente} onChange={onChangeSlct} 
                        etqVal={"Id"} etqId="Id" etqAlias={"Descr"} etiq={"Referente"} 
                        estilo={{ minWidth:14+'rem'}} nameEtq={"referente"}
                        tipo="outlined"
                    />                 
                </Box> 
                :   null
            }
            <Box mr={1}>
                <SelectEdo edo={edo} onChangeSlct={onChangeSlct} tipo="outlined" />
                <FormControl component="fieldset" size='small' style={{marginLeft:1+'rem'}}>          
                    <FormLabel  htmlFor="Género" size='small'>Género</FormLabel>        
                    <RadioGroup  row aria-label="position"  defaultValue="top" size='small'
                        name="genero" value={genero} onChange={onChangeGen}
                    >
                        <FormControlLabel value='H'  size='small'
                            control={<Radio color="primary" size='small'/>} label="Hombre" />    
                        <FormControlLabel value='M'   
                            control={<Radio color="primary" size='small' />} label="Mujer" />                         
                        <FormControlLabel value='X'   
                            control={<Radio color="primary" size='small' />} label="No Binario" />                         
                    </RadioGroup>                     
                </FormControl>
            </Box>              
            <Box  mt={2} mb={2} display="flex" flexDirection={"row"}>       
                          
                {listMun.length!==0 ?
                <Autocomplete
                    id="muni-select-nvoPerfil" size='small' style={{ width: 20.7+'rem' }}
                    onChange={(e,option)=>guardarMuni(option)}  value={Municipio}      
                    getOptionLabel={(option) => option.Nom} name="Municipio"             
                    noOptionsText={"Sin coincidencias"} options={listMun}
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Municipio" variant="outlined" />
                    )} 
                />
                :<LinearProgress/>}                           
                  {listSecc.length!==0 ?                
                <Box display={"flex"} mb={1}>
                     <SelectList lista={listSecc} value={Seccion} onChange={onChangeSlct} 
                        etqVal={"Id"} etqId="Id" etqAlias={"Numero"} etiq={"Sección"} 
                        estilo={{marginLeft:1+'rem', minWidth:8.5+'rem'}} nameEtq={"Seccion"}
                        tipo="outlined"
                    />                 
                </Box> 
                :   null
                }
            </Box>            
            {RelojFNacimiento()}
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:22+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
            />
            
            <br/>            
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:8+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'
            /> 
           {/*  <TextField  id="Email-input" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:22.8+'rem'}}
                label="Email"  value={Email} name='Email' size="small" autoComplete='off'
            />   */}
            <br/>
            {posicion.length!==0?
                <Box style={{width:30.8+'rem',height:12+'rem'}}>
                    <Card style={{display:'flex'}}>
                    <MapaAlerta Seleccionado={posicion} 
                        tamanio={{ height: "12rem", width: "100%"}}
                    />
                    </Card>
                </Box>
            :<Typography variant='h6'>Dirección no disponible.
            {" "+ mensajePos?mensajePos:""}
            </Typography>
            }

        <FormControl component="fieldset" style={{marginTop:"1rem"}}  >
            <FormLabel component="legend">¿Que le gustaría mejorar en el Dtto. 30?</FormLabel>
            <FormGroup row >
            <FormControlLabel
                control={<Checkbox checked={resp1} onChange={onCheck} name="resp1" />}
                label="Agua"
            />
            <FormControlLabel
                control={<Checkbox checked={resp2} onChange={onCheck} name="resp2" />}
                label="Movilidad"
            />
            <FormControlLabel
                control={<Checkbox checked={resp3} onChange={onCheck} name="resp3" />}
                label="Cultura"
            />
            <FormControlLabel
                control={<Checkbox checked={resp4} onChange={onCheck} name="resp4" />}
                label="Seguridad"
            />
            <FormControlLabel
                control={<Checkbox checked={resp5} onChange={onCheck} name="resp5" />}
                label="Economia"
            />
            </FormGroup>
 
        </FormControl>
        </div> 
    )     

    const cerrarBorrar = () => {            
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {  
           setNvoRegisPerfil({Nom:Nombre,llave:nvoRegistro.mensaje})                                   
        }
    };

    return (           
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}   
    >
        {DatosGenerales}
    </Modal>             
    )
}

export default ModalPerfilv2