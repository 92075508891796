import {useEffect, useState} from "react";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";

const mapContainerStyle = {
    height: "500px", width: "100%"
};

const center = {lat: 19.5721573, lng: -99.2726426};

const MapaPerfil = ({tamanio, libreriaG, zoom, coordPerfiles,}) => {

    const [auxMarcador, setAuxMarcador] = useState(center);

    useEffect(() => {
        setAuxMarcador(coordPerfiles.coordenada);
    }, [coordPerfiles]);

    return (<LoadScript
        googleMapsApiKey={process.env.REACT_APP_G_MAP_KEY}
        language="spanish" libraries={libreriaG}
    >
        <GoogleMap
            id="mapa-monit-alerta" center={auxMarcador} zoom={zoom}
            mapContainerStyle={tamanio ? tamanio : mapContainerStyle}
        >
            {coordPerfiles.length !== 0 ? <Marker style={{width: 50, height: 50}}
                                                  title={coordPerfiles.nombre} key={1}
                                                  position={coordPerfiles.coordenada}
                                                  icon={"marcador.svg"}
            /> : null}
        </GoogleMap>
    </LoadScript>);
};

export default MapaPerfil;
