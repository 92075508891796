import React, { useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {  IconButton, Table, TableBody, TableCell, 
        TableContainer, TableHead, TableRow,Checkbox ,Box, Typography, Button  } from '@material-ui/core';
import {useHistory} from "react-router-dom";
import { celda } from '../generales/TablaGeneral';
import CheckPermisos from './CheckPermisos';
import {CheckCircle,CheckCircleOutline} from '@material-ui/icons/';
import {toast} from "react-toastify";
import qs from 'qs';
import axios from 'axios';
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectList from './SelectList';
import { obtenDispo } from '../funciones/IdDispositivo';
import EditEstructura from './EditEstructura';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '20rem',
     
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaPermisoMun({  datos, usrRegistro,llamaApi,listEstruc, listEdo, listTipoUsu, getEditUsu}) {    
    const nvlSNPermiso = '5'   
    let history = useHistory();
    const classes = useStyles();
    const auxDispV =obtenDispo()        
    const idusu = localStorage.getItem("UsuId");
    const source = axios.CancelToken.source();
    const [selectedID, setSelectedID] = useState(null);
    const [checked, setChecked] = useState(true);
    const [todo, setTodo] = useState(false);
    const [espera,setEspera]=useState(false);
    const [listTodo,setlistTodo]=useState([]);
    const [listMuni,setListMuni]= useState([]);
    const [modalEditEstrc,setModalEditEstrc]= useState(false);
    const [conteo,setConteo]= useState({selecc:0,total:0});        
    const [usrMulti,setUsrMulti]= useState({nombre:'',edo:null,TipoUsu:null,muni:null});
    const {nombre,edo,TipoUsu,muni}=usrMulti  
    const {selecc,total }=conteo  

    useEffect(() => {
        let bandera=true, statusActivo=0
        const filtrado=datos.filter((aux)=>aux.DetStatus === 0)        
        if (filtrado.length!==0 || datos[0].Id<1) 
        {   bandera=false
        }
        if (datos.length!==0) {
            statusActivo=datos.length - filtrado.length    
        }else{
            statusActivo=0
        }
        
            
        
        //console.log({total:datos.length,selecc:statusActivo});
        setConteo({total:datos.length,selecc:statusActivo})
        setlistTodo([])
        setTodo(bandera)
        return () => {
            source.cancel();
        }
    }, [datos])   

    const onChangeSlct=(e)=>{    
        //console.log("limpieando",e.target.value,e.target.name);
         if (e!== null && e!== undefined) 
        {   setUsrMulti({...listMuni,[e.target.name]:e.target.value})   
        }
        else
        {   setUsrMulti({...listMuni,[e.target.name]:""})          
        }        
    }

    const seleccionado = (auxId, auxOrden) => 
    {   setSelectedID(auxId)       
    }

    const handleChange = (event) => 
    {   setChecked(event.target.checked);
    };    

    const editPermiso = (arr) => {
          
        const data = qs.stringify({            
            idBase: usrRegistro.IdBase,   
            idUsuario:usrRegistro.UsrId,                 
            usuario:idusu,                
            detalle:JSON.stringify(arr ),
            idDispositivo: auxDispV
        });

        const url = 'usuario/v2/estructura-detalle-edit';

        function response(auxiliar) {
            let mensaje =""
            if (auxiliar.respuesta == 1) 
            {   mensaje = `Se modificaron todos los registros.`    
                llamaApi( )
            }                                
            toast.success(mensaje, {
                toastId: `edit-user-per${usrRegistro.UsrId}`,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
        llamaApiCargaToken( data ,url, response, ErrorGeneral, setEspera, history, source);
    }

    const cambioTodo=(e)=>{
        e.preventDefault()
        setTodo(!todo)
        let arr=[]
        datos.forEach((element)=>{
            arr.push({id:element.Id,val:!todo?1:0})
        })
        setConteo({total:datos.length, selecc:!todo? datos.length : 0})
        setlistTodo(arr)    
        editPermiso(arr)    
    }
    

    function tablaCompleta(auxlista) {//usuario
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                 
                    <TableCell align="center" padding='none'> 
                    <IconButton size="small" onClick={cambioTodo} component="span" 
                            disabled={auxlista[0].Id<1 ?true:false} aria-label="editar-check" 
                        >
                            { espera ?                             
                                <CircularProgress size={20}/>                             
                            :   (todo ? 
                                    <CheckCircle color='primary' />
                                :   <CheckCircleOutline  /> 
                                )
                            }
                        </IconButton>
                    {`${selecc} / ${total}` }</TableCell>    
                    <TableCell align="center" padding='none'>{`${usrRegistro.TpEstrNom} asignados`} </TableCell>    
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length !== 0 ? 
                    (auxlista.map((row) => (
                        <CheckPermisos key={row.Id} registro={row}  usrRegistro={usrRegistro} 
                            llamaApi={llamaApi} listTodo={listTodo}
                            //listaOriginal={listaOriginalPerm}
                        />                    
                    ))) 
                : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>            
                    <TableCell align="left">{`${usrRegistro.TpEstrNom} a asignados`} </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.tableRow}>
                    {celda("left", {}, "Sin Perfiles", 1, classes.tableCell)}                 
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }    
    const tabla=true?tablaCompleta(datos) : null 
    const contenido = datos.length !== 0 ? tabla : tablaSinDatos()

    const modNvoUsr = modalEditEstrc ? 
                        ( <EditEstructura modalAbierto={modalEditEstrc}  
                            setModalAbierto={setModalEditEstrc} titulo={'Editar Estructura'}                            
                            listTipoUsu={listTipoUsu} listEstruc={listEstruc}
                            getEditUsu={getEditUsu} listMuni={listMuni}         usrRegistro={usrRegistro}                   
                        />
                        ): null; 
    return (
    <span>
        {/* {slctTipo}
        {slctEdo}
        {slctMun} 
        <Button onClick={()=>setModalEditEstrc(true)}>Cambio</Button>  
        */}        
        {selecc=== 0? <Button onClick={()=>setModalEditEstrc(true)} variant='outlined'>Editar Estructura</Button> :null}
        {modNvoUsr}
        {contenido}
    </span>
    );
}