import React,{useState} from 'react'
//import { makeStyles } from '@material-ui/core/styles';
//import clsx from 'clsx';
import {Box, TextField,FormControl, Tooltip,LinearProgress,
        Select,MenuItem,InputLabel, Card,Typography} from '@material-ui/core' 
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
import moment from 'moment'
//import Visibility from '@material-ui/icons/Visibility';
//import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MapaAlerta from '../perfiles/MapaAlerta'
//import Geocode from "react-geocode";
import {llamadaApi,ErrorGeneral,llamadaApiCarga } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
const ModalNvoPerfil = ({modalAbierto,setModalAbierto ,titulo,listMun,listSecc,listPlantilla,setModalAvisoImg,setNvoRegisPerfil}) => {
    let history = useHistory();
    //const classes = useStyles();
    //Geocode.setApiKey({process.env.REACT_APP_G_MAP_KEY});
    //Geocode.setLanguage("en");
    //Geocode.setRegion("es");
    //const fechaMax = moment(new Date()).subtract(18,'years')    
    const fechaMax = moment("2005-06-30T00:00:00")
   // console.log(fechaMax);
    const[nvoCliente,setNvoCliente]= useState({Nombre:'', FNacimien:fechaMax, Email:'' ,calle:'',
                                                Tel:'',ine:"",Municipio:"",Seccion:'',plantilla:0
                                            });      
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)
    const [aviso, setAviso] = useState(false);
    const[auxListSecc,setAuxListSecc]= useState([]);
    const{  Nombre,FNacimien,Email,calle,Tel,ine,Municipio,Seccion,plantilla}=nvoCliente
    const[posicion,setPosicion]= useState([])
    const[mensajePos,setMensajePos]= useState("")
    const[cambioEfectivo,serCambioEfectivo] = useState(false)    
    const[nvoRegistro, setNvoRegistro] = useState([])   
    const[validaPerfil, setValidaPerfil] = useState({perfil:false,nombre:false})  
    const[arrError,setArrError] = useState([false,false,false,false,false,false])     
    const source = axios.CancelToken.source();  
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    React.useEffect(()=>{
        //console.log(listMun);
        //console.log(listPlantilla[0].Id);
        setNvoCliente({...nvoCliente,plantilla:listPlantilla[0].Id})
        getLocation()

    },[])

    const guardarFNaci = auxfecha =>
    {   
        setNvoCliente({
            ...nvoCliente,
            FNacimien : auxfecha  
        })
    };
    function getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } 
        else 
        {   //console.log("La Geolocalizacion no es soportada por tu navegador.");
            setMensajePos( "La Geolocalizacion no es soportada por tu navegador.");
            setPosicion([])
        }
      }
      
    function showPosition(position) {
        let Latitude= position.coords.latitude ;
        let Longitude= position.coords.longitude;
        //console.log("sin obtener la location");
        /* Geocode.fromLatLng(Latitude, Longitude).then(
            (response) => {
              const address = response.results[0].formatted_address;
              console.log(address);
            },
            (error) => {
              console.error(error);
            }
        ); */
       // console.log({lat:Latitude,Lng:Longitude});
        setPosicion({Lat:Latitude,Lon:Longitude})
    }

    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
        <Tooltip title="Seleccionar Fecha Nacimiento">
          <Box  mr={1}  component="span" width={1/4}>           
            <DatePicker style={{width:8.3+'rem'}}
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"    
              minDate="1900-03-14" maxDate={fechaMax}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
        </Tooltip>                                             
        </MuiPickersUtilsProvider>
    }

    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};             

    const verifiNombre = () => {                   
        let data = qs.stringify({
            cadena:Nombre.trim(),    
            idDispositivo: auxDispV,
        });
        let url = "perfil-verifica-cadena";
        llamadaApiCarga(data, url, respuestaNombre,ErrorGeneral,setEspera,history);
    };

    function respuestaNombre(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0                        
        let auxArr=arrError
        if (isEmpty || auxiliar.coincidencia===0 ||  auxiliar.coincidencia==="0"   ) 
        {                 
            console.log(auxiliar);   
            auxArr[3]=false 
            setArrError(auxArr)
            //setAviso(false)   
            let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
            validaDuplicado(auxFechaNacimient)              
        } 
        else 
        {   let mensaje = "Nombre invalido, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${5}`
            })              
            mandaAlerta(mensaje, "warning");              
            auxArr[3]=true                                
            setArrError(auxArr)
            setBtnDeshabilitado(false)
           // setBtnDeshabilitado(true)                      
        }
         
    }

    const validaDuplicado = (fecha) => {              
        let data = qs.stringify({
            nombre:Nombre.trim(),
            nacimiento:fecha+"T00:00:00",
            municipio:Municipio.Id,
            idDispositivo: auxDispV,
        });
        let url = "perfil-verifica-duplicado";
        llamadaApiCarga(data, url, respuestaDuplicado,ErrorGeneral,setEspera,history);
    };

    function respuestaDuplicado(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0
        let auxArr=arrError
        if (isEmpty || auxiliar.rechazo===0 ||  auxiliar.rechazo==="0"   ) 
        {   //             
            auxArr[3]=true     
            setArrError(auxArr)     
            console.log(auxiliar);
            let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
            llamadaNvoPerfil(auxFechaNacimient+'T00:00:00')  
            //setBtnDeshabilitado(false)     
        } 
        else 
        {   //let mensaje = "Nombre ya registrado, ingrese un Nombre diferente.";
            let mensaje = auxiliar.mensaje//"Nombre ya registrado, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${6}`
            })                                    
            mandaAlerta(mensaje, "warning");
            auxArr=arrError
            auxArr[3]=true                    
            setArrError(auxArr) 
            setBtnDeshabilitado(false)               
        }
    }

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
            if (e.target.value.length===10) {
                setBtnDeshabilitado(false)                     
            }            
            else{
                setBtnDeshabilitado(true)                     
            }
        }		    

	}; 
 
    
    async function llamadaNvoPerfil( auxFechaNacimient)  {       
        setEspera(true);
        let auxU=process.env.REACT_APP_LINK +`perfil-nuevo-1-3`;                               
        let token =localStorage.getItem('token20') ;               
        let usuId =localStorage.getItem('UsuId') ;        
        let Dispvo =localStorage.getItem('Dispvo') ;    
        let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'                                   
        let dataInfo = qs.stringify({                                                                               
            'usuario':usuId,
            'telefono':Tel,
            'nombre':Nombre,
            'latitud':posicion.length > 0 ? posicion.Lat : 0,
            'longitud':posicion.length > 0 ? posicion.Lon : 0,
            'direccion':calle,
            'email':Email,
            'version':'WEBSYSTEM',
            'plantilla':plantilla,
            'nacimiento':auxFechaNacimient,
            'ine':ine,
            'municipio':Municipio.Id,
            'seccion':Seccion,
            'idDispositivo':auxDispV
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
            aux=  response.data;                                          
            console.log(aux)
            if (aux.length!==0) 
            {                
                //setNvoRegistro(aux[0]);  
                //setActualizar( !actualizar)              
                setBtnDeshabilitado(true)                                             
                let mensaje="Se registro correctamente el Perfil"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);
                //setAviso(true)     
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-perfil${1}`
                })
                setNvoRegisPerfil({Nom:Nombre,llave:aux.mensaje})
                setModalAbierto(false);
                setNvoRegistro(aux)
                serCambioEfectivo(true) 
            }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
        });             
        setEspera(false);         
    };

    const onChangeCorreo = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoCliente({
                    ...nvoCliente, //copiamos el nvocliente
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
            //console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
  
   /*  const verificaCorreo=(correo)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');        
        if( correo.trim() === '' || correo.trim() === null  )
        {  // respuesta=true;      
            setMensaje('Campo Email vacio, debe llenar el campo ' );
            setTipoAdvertencia("warning")  
            setAviso(true);                     
        }
        else{
            if ( expCadena.test(correo)  ) 
            {   respuesta=true;
            }
            else
            {   setMensaje('Debe escribir un Correo valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);                           
            }            
        }
        return(respuesta);
    }    */  

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }

    /* function verificaUsu() 
    {   let respuesta=false
        if ( !verificaNulos(Email,'Email') ) 
        {   if (Email.length>=8 ) 
            {                
                respuesta=true                    
            }else
            {   mandaAlerta('Campo Email incompleto, minimo 8 caracteres , debe escribir un Email valido',"warning") 
            }
        }        
        return respuesta;
    } */
 
    function guardarDatos(e)
    {   e.preventDefault()        
        console.log("Se guardo correctamente");
        //serCambioEfectivo(true)          
        let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
        if (!verificaNulos(Municipio,"Municipio") &&!verificaNulos(Seccion,"Sección")  && validaVariable(Nombre,'Nombre',7)             
            && validaVariable(Tel,'Telefono',10))
        {           
            setMensaje('');                
            setAviso(false);    
            //console.log(Municipio.Id);  
            setBtnDeshabilitado(true)   
            verifiNombre()                  
            //llamadaNvoPerfil(auxFechaNacimient+'T00:00:00')        
        }                                  
    } 
      
    const guardarMuni=(e)=>{    
        if (e!== null && e!== undefined) 
        {               
            let seccFilt=listSecc.filter((auxSeccion)=>auxSeccion.MuniId===e.Id)
            //console.log(seccFilt);      
            setAuxListSecc(seccFilt)    
            setNvoCliente({
                ...nvoCliente,
                Municipio : e  ,  
                Seccion :""  
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        {   setNvoCliente({
                ...nvoCliente,
                Municipio : "" ,Seccion :""   
            })           
            setAuxListSecc([])  
        }        
    }

    const guardarSeccionSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoCliente({
                ...nvoCliente,
                Seccion :e.target.value 
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        { setNvoCliente({
            ...nvoCliente,
            Seccion : ""  
        })           
        }        
    }  

    const DatosGenerales=(
        <div>            
           <Box   pt={1} mb={1} display="flex" flexDirection={"row"}>
                {listMun.length!==0 ?
                <Autocomplete
                    id="muni-select-nvoPerfil" size='small' style={{ width: 20.7+'rem' }}
                    onChange={(e,option)=>guardarMuni(option)}  value={Municipio}      
                    getOptionLabel={(option) => option.Nom} name="Municipio"             
                    noOptionsText={"Sin coincidencias"} options={listMun}
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Municipio" variant="outlined" />
                    )} 
                />
                :<LinearProgress/>}
                {auxListSecc.length!==0 ?                
                <Box display={"flex"} mb={1}>
                <FormControl size='small'  style={{marginLeft:1.6+'rem', minWidth:8.5+'rem'}} >
                    <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                    <Select  size='small' variant="outlined"
                        label="Sección" id="Seccion-select2" name="Seccion"
                        value={Seccion} onChange={guardarSeccionSelect}
                    >
                        {auxListSecc.map((secc, index) => {
                            if(secc.Id>0) {
                            return(
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Numero }</em>
                            </MenuItem>
                            )}
                        } )}                    
                    </Select>
                    </FormControl>
                    </Box>
                :   null
                }
            </Box>               
            {RelojFNacimiento()}
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:22+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
            />
            
            <br/>
            {/*  <TextField  id="ine-input2" onChange={onChangeCorreo} style={{width:12+'rem',paddingRight:.8+'rem',paddingBottom:.5+'rem',}}
                label="Ine"  value={ine} name='ine' size="small" inputProps= {{ maxlength: 18}}
                autoComplete='off'
            /> 
            <TextField  id="calle-input2" onChange={onChangeCorreo} style={{width:18.8+'rem', paddingBottom:.5+'rem',}}
                label="Calle"  value={calle} name='calle' size="small" 
            />                    
            <br/> */}
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:8+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'
            /> 
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:22.8+'rem'}}
                label="Email"  value={Email} name='Email' size="small" autoComplete='off'
            />  
            <br/>
            {posicion.length!==0?
                <Box style={{width:30.8+'rem',height:12+'rem'}}>
                    <Card style={{display:'flex'}}>
                    <MapaAlerta Seleccionado={posicion} 
                        tamanio={{ height: "12rem", width: "100%"}}
                    />
                    </Card>
                </Box>
            :<Typography variant='h6'>Dirección no disponible.
            {" "+ mensajePos?mensajePos:""}
            </Typography>
            }
        </div> 
    )     

    const cerrarBorrar = () => {    
        //setOpen(false);        
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {   //setActualizar(!actualizar)  
           // setNvoRegisPerfil({Nom:Nombre,llave:203})
           setNvoRegisPerfil({Nom:Nombre,llave:nvoRegistro.mensaje})                                   
        }
    };

    return (
    <>           
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}   
    >
        {DatosGenerales}
    </Modal>         
    </>
    )
}

export default ModalNvoPerfil