import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Polygon,Marker } from '@react-google-maps/api';
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
//import { Typography ,Box, Paper, Card, IconButton} from '@material-ui/core';
//import CancelIcon from '@material-ui/icons/Cancel';
import CajaLeyenda from './CajaLeyenda';
import {tipoIcReco, infoReco} from '../funciones/Mapas/FuncionMapas'

  let arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
                    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

export default function MapaConsultaMarcador({poligonos,centro ,setCambiaMarcador,redimensionar,zoom,listMapProm,listApoyos,listRecorrido,auxTam,  checkEtiqSecc,setCheckEtiqSecc}){  
  let altuTamMax=825
  let anchoTamMax=1535
  const [rutaPol, setRutaPol] = useState([]);
  const [autocomplete, setAutocomplete] = useState([]);  
  const [auxColores, setAuxColores] = useState(arreColores);
  const [alturaContenedor, setAlturaContenedor] = useState (
                                            (!redimensionar) ? (window.innerHeight<altuTamMax ? window.innerHeight-165: window.innerHeight - 200)
                                                             : (window.innerHeight<altuTamMax ? window.innerHeight-215: window.innerHeight - 250))
  const [anchoContenedor, setAnchoContenedor] = useState(
                                            (!redimensionar) ? window.innerWidth<anchoTamMax ? window.innerWidth-200 : window.innerWidth-300
                                                             : window.innerWidth<anchoTamMax ? window.innerWidth-510 : window.innerWidth-750)  
  const [tamDefinido, setTamDefinido] = useState([])     
  const [leyendaMap, setLeyendaMap] = useState([]);

  useEffect(()=>
  {     
    //console.log(poligonos);
    //console.log(centro);
    if (poligonos&&poligonos.length!==0)
    {      
      let ordenado=[]
      poligonos.forEach((poligono) => {
        let arr=[]
        if (poligono.gps) {
          poligono.gps.forEach((coorde)=>{
            let auxCoord=generaCoordenadaDinam(coorde.Lat, coorde.Lon) 
            arr.push(auxCoord)                 
          })
          const auxCentro=generaCoordenadaDinam(poligono.Lat, poligono.Lon) 
          ordenado.push({Id:poligono.Id, centro:auxCentro, secc:poligono.Num,
            gps:arr, Color:poligono.Color ? poligono.Color:""})  
        }        
      })

      setRutaPol(ordenado);

      let auxNumSecc = poligonos.length
      if (auxNumSecc > arreColores.length) 
      {
        let auxArregloColor=[]
        for (let index = 0; index < auxNumSecc; index++) 
        { let nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
          auxArregloColor[index] = arreColores[nivel]          
        } 
        setAuxColores(auxArregloColor)
      }          
    }

    setCambiaMarcador(true)      

    if (auxTam   ) {
      setTamDefinido({wdt:auxTam.wd, hgt:auxTam.hgt})
    }
    else{
      setTamDefinido([])
    }

    return()=>{
      setCheckEtiqSecc(false)
    }
  },[centro,poligonos]);

useEffect(()=>{
  window.addEventListener('resize', function() {
    if(!redimensionar){
      if(window.innerHeight<altuTamMax)
      { setAlturaContenedor(window.innerHeight-165)
      }
      else
      { setAlturaContenedor(window.innerHeight-200)
      }
      if(window.innerWidth<anchoTamMax)
      { setAnchoContenedor(window.innerWidth-200)
      }
      else
      { setAnchoContenedor(window.innerWidth-200)
      }
    }else{
      if(window.innerHeight<altuTamMax)
      { setAlturaContenedor(window.innerHeight-200)
      }
      else
      { setAlturaContenedor(window.innerHeight-300)
      }
      if(window.innerWidth<anchoTamMax)
      { setAnchoContenedor(window.innerWidth-510)
      }
      else
      { setAnchoContenedor(window.innerWidth-750)
      }
    }
  });
},[alturaContenedor,anchoContenedor,anchoTamMax,altuTamMax,redimensionar])

  const onLoad =(respuesta)=> {
   // console.log('autocomplete: ', respuesta)

    //autocomplete = respuesta
    setAutocomplete(respuesta);
    console.log(autocomplete)
    //this.gps = autocomplete.getPlace().geometry.location
  }
 
  const options =(Color,numero)=> {    
    const auxiliar={  
    fillColor: Color.length!==0?Color: auxColores[numero],
    fillOpacity: 0.5,
    strokeColor: "#0097A7",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }

  const onLoadPolygono = marker => {
    //console.log('Poligono: ', marker)
  }   

  //AIzaSyAw91KtccbYS3AN0Lex6799itZyB7VXjq0
  return (//    "AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" process.env.REACT_APP_G_MAP_KEY
  <LoadScript googleMapsApiKey={process.env.REACT_APP_G_MAP_KEY} 
  libraries={["places"]}> 
    <GoogleMap
      id="searchbox-example" zoom={zoom} center={centro}
      mapContainerStyle={{ width: tamDefinido.length !==0 ? tamDefinido.wdt: anchoContenedor, height: tamDefinido.length !==0 ? tamDefinido.hgt : alturaContenedor }}                
    >  
    {leyendaMap.length!==0?
      <CajaLeyenda leyendaMap={leyendaMap} setLeyendaMap={setLeyendaMap} galeria={[]} />      
    :null }   
    { rutaPol!==null && rutaPol.length!==0?
      rutaPol.map((poligono,index) => 
      {
        return(
        <Polygon
          onLoad={onLoadPolygono} paths={poligono.gps}
          options={options(poligono.Color, index)}
        />)
      })      
      :null
    }       
     {checkEtiqSecc&& rutaPol!==null && rutaPol.length!==0?
      rutaPol.map((poligono) => 
      {         
        return(   
          <Marker style={{width:80+'px',height:80+'px'}}
          title={`${poligono.secc}`}   key={poligono.Id}
          position={ poligono.centro}   
          icon= {{url:`https://daduva-cartografia.sfo3.cdn.digitaloceanspaces.com/Sec/Secc${poligono.secc}.svg`}} 
       />   )
      })      
      :null
      }  
     {listMapProm && listMapProm.length!==0 ?
        listMapProm.map((perfil,index)=>{
          const auxCoord=generaCoordenadaDinam(perfil.Lat, perfil.Lon) 
          //console.log(auxCoord);
          const aviso=perfil.Gps===0?"\nSin Coordenadas":""
          const icono=perfil.Gps=== 1 ? (perfil.StatusId===2 ? "marcador-valido.svg":(perfil.StatusId===1? "marcador-pendiente.svg":"marcador-rechazado.svg"))
          :( perfil.StatusId===2 ? "marcador-valid-snc.svg":(perfil.StatusId===1? "marcador-pend-snc.svg":"marcador-rech-snc.svg") )
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${perfil.Nom} \n${perfil.Tel} ${aviso}`}   key={index}
              position={ auxCoord}  
              icon= {icono}                   
            />)
        })
      :null}
      {listApoyos && listApoyos.length!==0 ?
        listApoyos.map((perfil,index)=>{
          const auxCoord=generaCoordenadaDinam(perfil.Lat, perfil.Lon)                     
          const icono="marcador-militante.svg"       
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${perfil.Obsrv} \n${perfil.Dir} `}   key={index}
              position={ auxCoord}  
              icon= {icono}                   
            />)
        })
      :null}
      {listRecorrido && listRecorrido.length!==0 ?
        listRecorrido.map((recorr,index)=>{//0.0000010
          const auxCoord=generaCoordenadaDinam(recorr.Lat,recorr.Lon )                     
          return(
          <Marker style={{width:50+'px',height:50+'px'}}
            title={`${recorr.TipoNom} \n${recorr.Obsrv} `}   key={index}
            position={ auxCoord}   onClick={()=>infoReco(recorr, auxCoord, true, setLeyendaMap)} 
            icon= {tipoIcReco(recorr.TipoId)}                   
          />)
        })
      :null}
    </GoogleMap>
  </LoadScript>);
}
 