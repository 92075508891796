import axios from "axios";
const source = axios.CancelToken.source();
export async function llamadaApi(data, url, miFuncion,funcionError,historial) {
  const token =localStorage.getItem('token20') ;         
  const config = {
      method: "post",
      url: process.env.REACT_APP_LINK + url,
      headers: { 'access-token' : token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      cancelToken: source.token,
    };
    await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);           
      })
      .catch(function (error) {
        funcionError(error,historial,url)
      });
}

export async function llamadaApiCarga(data, url, miFuncion,funcionError,setEspera,historial) {
  const token =localStorage.getItem('token20') ;     
    setEspera(true)
    const config = {
      method: "post",
      url: process.env.REACT_APP_LINK + url,
      headers: { 'access-token' : token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      cancelToken: source.token,
    };
    await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);           
      })
      .catch(function (error) {
        console.log(error);
        funcionError(error,historial,url)
      });
    setEspera(false)
}

export async function llamaApiCargaToken(data, url, miFuncion,funcionError,setEspera,historial,toknCancela) {
  const token =localStorage.getItem('token20') ;     
  setEspera(true)
  const config = {
    method: "post",
  url: process.env.REACT_APP_LINK + url,   
    headers: { 'access-token' : token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
    cancelToken: toknCancela.token,
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      console.log(error);
      funcionError(error,historial,url)
    });
  setEspera(false)
}

export async function llamadaApiToken(data, url, miFuncion,funcionError,historial,toknCancela) {
  const token =localStorage.getItem('token20') ;         
  const config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers: { 'access-token' : token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
    cancelToken: toknCancela.token,
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      funcionError(error,historial,url)
    });
}

export async function llamaApiFormData(data, url, miFuncion,funcionError,setEspera,historial,toknCancela,auxSize,setProgresoEnvio) {
  const token =localStorage.getItem('token20') ;
  setEspera(true)
  const config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers: { 'access-token' : token,'size':auxSize,
      "Content-Type": "multipart/form-data", estado: process.env.REACT_APP_EDO,  clase: 'RECORRIDO',  tipo: 'SPACE',
    },
    data: data,
    cancelToken: toknCancela.token,
    onUploadProgress:data => {
      let progreso= ((100 * data.loaded) / data.total).toFixed(2)
      setProgresoEnvio(progreso)
    },
  };
  await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);
      })
      .catch(function (error) {
        console.log(error);
        funcionError(error,historial,url)
      });
  setEspera(false)
}

export async function llamaApiFormDat2(data, url, miFuncion,funcionError,setEspera,historial,toknCancela,auxSize,estado,clase,setProgresoEnvio) {
  const token =localStorage.getItem('token20') ;
  setEspera(true)
  const config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers: { 'access-token' : token,'size':auxSize,
      "Content-Type": "multipart/form-data", estado: estado,  clase: clase,  tipo: 'SPACE',
    },
    data: data,
    cancelToken: toknCancela.token,
    onUploadProgress:data => {
      let progreso= ((100 * data.loaded) / data.total).toFixed(2)
      setProgresoEnvio(progreso)
    },
  };
  await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);
      })
      .catch(function (error) {
        console.log(error);
        funcionError(error,historial,url)
      });
  setEspera(false)
}

export async function llamaApiFormDat3(data, url, miFuncion, funcionError, setEspera, historial, toknCancela, headers, setProgresoEnvio,mensajeError) {
  const token =localStorage.getItem('token20') ;
  setEspera(true)
  const config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers:{...headers, 'access-token' : token, "Content-Type": "multipart/form-data"},
    data: data,
    cancelToken: toknCancela.token,
    onUploadProgress:data => {
      let progreso= ((100 * data.loaded) / data.total).toFixed(2)
      setProgresoEnvio(progreso)
    },
  };
  await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);
      })
      .catch(function (error) {
        console.log(error);
        mensajeError()
        funcionError(error,historial,url)
      });
  setEspera(false)
}

export function ErrorGeneral(error,historial,url) {
  //console.log(url);
  console.log(error);            
  if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
  { console.log('datos incorrectos' );  
  }
  else
  {
    if ( ( error && error.response && error.response.data && error.response.data?.error?.name==="JsonWebTokenError" )||
        ( error && error.response && error.response.data && error.response.data?.error?.name==="TokenExpiredError" ) ) 
    {
      console.log('loguearse de nuevo');
      localStorage.clear();                            
      historial.push("/");                              
    }
    else{
      if ( ( error && error.response && error.response.data && error.response.data.mensaje==="No se agrego el Token en los Headers de la petición." )) 
      {
        console.log('loguearse de nuevo');
        localStorage.clear();                            
        historial.push("/");                              
      }
    } 
  }      
}