import React,{useState,useEffect,useContext} from 'react';
import {Box,LinearProgress,//IconButton,Typography
    IconButton,Tooltip,FormControl,InputLabel,Select,
    MenuItem,TextField,FormControlLabel,Checkbox,
    Breadcrumbs,Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
//import TablaDto from './TablaDto';
import Graficas from './GraficasRegion';
import CardTotal from './CardTotal';
import MapaDto from '../MapaEdo/MapaDto';
import TablaMun from './TablaMun';
import GraficaPolar from './GraficaPolar';
import IntervaloSelect from '../perfiles/IntervaloTabla';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';
import TablaSeccion from './TablaSecc';
import moment from 'moment';
import AvanceRecorrido from '../AvanceRecorrido';
import AvancePerfilUsr from '../Av-PerfilxUsr';
const useStyles = makeStyles({
 
    textoSelect: {
        '&:hover': {
          color:'#9ccdfa',
        },
        fontSize:1.25+'rem'
    },
});

const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
"#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const AvanceDto = () => {
    const classes = useStyles();
    const history = useHistory();
    const ColBase=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const auxId =localStorage.getItem('UsuId') ;  
    const nvl= localStorage.getItem('Tipo');          
    const usu= localStorage.getItem('UsuarioNom');   
    const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 ? localStorage.getItem('Dispvo') :'w8rf51v21dsd2cs'      
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);    
    const [listTotl, setListTotl] = useState([])                   
    const [listAv, setListAv] = useState([])                
    const [espera, setEspera] = useState(false)       
    const [listDistrito, setListDistrito] = useState([])           
    const [registro, setRegistro] = useState({Id:0,Num:0})      
    const [regMun, setRegMun] = useState(null)            
    const [listAvMun, setListAvMun] = useState([])        
    const [listGralMap, setListGralMap] = useState([])        
    const [listGralOrg, setListGralOrg] = useState([])        
    const [mapRegis, setMapRegis] = useState(null)        
    const [long,setLong] = useState(-99.2809305);
    const [lat,setLat] = useState(19.4773566);
    const [zoom, setZoom] = useState(11 );
    const [fSelect, setFSelect] = useState({inicio:new Date("2024-02-01T00:00:00"),final:new Date()});
    const [buscar, setBuscar] = useState(false);
    const [tipoTabla, setTipoTabla] = useState(2);
    const [opcion,setOpcion] = useState(1)

    const reiniciar=()=>{
        setTipoTabla(1)
        setListTotl([])                   
        setListAv([])                                
        setRegistro({Id:0,Num:0})      
        setRegMun(null)            
        setListAvMun([])        
        setListGralMap([])                
        setMapRegis(null)        
        setLong(-99.2809305);
        setLat(19.4773566);
        setZoom( 11 );        
        setBuscar(!buscar);
    }

    useEffect(()=>{             
        const autenticado = authUser();
        if(!autenticado)
        {   localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {   if (usuario.length===0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                           
            }                       
            let auxArregloColor=[], auxArregloColor2=[]
            if (125 > arreColores.length) 
            {    
                for (let index = 0; index < 125; index++) {                    
                    const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                    auxArregloColor.push({pos:index + 1 ,auxColor:arreColores[nivel]})      
                }                         
                /* aux.forEach((element,index )=> {
                    const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                    auxArregloColor.push({pos:element.Id,auxColor:arreColores[nivel]})      
                }); */
                for (let index = 0; index < arreColores.length; index++) 
                {
                    let auxPos=[]
                    const filtrado=auxArregloColor.filter((color)=>color.auxColor===arreColores[index] )  
                    filtrado.forEach((color,indice)=>{ 
                        if (color.pos >= 1 && color.pos<=9) {
                            auxPos[indice] = `R0${color.pos}`    
                        }
                        else{
                            auxPos[indice] = `R${color.pos}`
                        }                       
                        
                    })              
                    auxArregloColor2.push({pos:auxPos,auxColor:arreColores[index]})      
                }
                //console.log(auxArregloColor2);    
                setListGralMap(auxArregloColor2)
                setListGralOrg(auxArregloColor2)
            }  
            guardarGenerales({...generales,Titulo:'Avance General'})      
            llenaTotales(1,0)            
          //  llenaListDistr()
        }
        
        return ()=> {
            source.cancel();
        }      
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[buscar]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {            
            if (registro!==null &&  registro?.Id!==0) 
            {
             //console.log(registro);
                llenaTotales(2,registro.Id)

                //const filtro =listTotl[0].estado.filter((edo)=>edo.EdoId===registro.Id)
                //setListAv(filtro)             

                 let editable=listGralOrg.filter(obtenPos )
                if (editable.length!==0) 
                {   let nvo=[]
                    listGralOrg.forEach(element => {
                        let aux=element
                        if (Array.isArray(element.pos) && element.pos.length === editable[0].pos.length && element.pos === editable[0].pos) 
                        {   let nvoPos=[]
                            for (let index = 0; index < element.pos.length; index++) {
                                
                                if (element.pos[index] === `${registro.Id}`) {                                
                                    nvo.push({pos:[`${registro.Id}`],auxColor:ColBase})
                                }else{
                                    nvoPos.push(element.pos[index])
                                }
                                
                            }
                            if (element.pos === editable[0].pos) {
                                aux={pos:element.pos,auxColor:ColBase}
                            }  
                         
                            aux={pos:nvoPos,auxColor:element.auxColor}
                           // console.log(aux);
                             
                        }
                        else
                        {   if (typeof element.pos === 'string' || element.pos instanceof String)
                            {   if (element.pos === `${registro.Id}`) 
                                {   aux={pos:[`${registro.Id}`],auxColor:ColBase}
                                }
                            }
                        }
                        
                        nvo.push(aux)
                    });
                   //console.log(nvo);
                    //setListGralMap(nvo)
                }  
             const coord=listTotl[0].estado.filter((dist)=>dist.EdoId ===registro.Id)
                if (coord.length!==0) {
                    setLong(coord[0].Lon) 
                    setLat(coord[0].Lat)
                    setZoom(11)
                }  
               //  console.log(coord);
            }else{
                //setListGralMap(listGralOrg)
                setListAv(listTotl[0].avc_gral)  
                setListAvMun([])
                setRegMun(null)
                setLong(-99.2809305) 
                setLat(19.4773566)
                setZoom( 11 )
            }    
        }
        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[registro]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {
            if (mapRegis!==null  ) 
            {   
                setRegistro({Id:parseInt(mapRegis.CVEGEO.replace("R", '')),Num:mapRegis.NOMREG})
                setTipoTabla(2)
               // llenaTotales(2,mapRegis.DTO_ID)
             
            } 
        }
        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mapRegis]);

    useEffect(()=>{
        //console.log(regMun,listTotl);
        if (regMun) {
            const filtro=listTotl[0].estado.filter((reg)=>reg.EdoId===regMun.MunId)
            setLong(filtro[0].Lon);
            setLat(filtro[0].Lat);
            llenaTotales(3,regMun.MunId) 
            setZoom( 12 );    
        } 
    },[regMun])

    function obtenPos(num) {
        let bandera=false
        //console.log(num);
        for (let i = 0; i<num.pos.length ; i++) {
            const pos=num.pos[i]
          //  console.log(pos);
          if (pos=== `${registro.Id}`) {
            bandera=true;
          }
        }
        return bandera;
    }

    function llenaListDistr()  {            
        const auxU= `lugar-distrito-tipo`;                            
        const dataInfo = qs.stringify({     
            usuario:auxId,
            tipo:"F",                                       
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListDistrito(aux)
                let auxNumDist = aux.length
                let auxArregloColor=[], auxArregloColor2=[]
                if (auxNumDist > arreColores.length) 
                {                             
                    aux.forEach((element,index )=> {
                        const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                        auxArregloColor.push({pos:element.Id,auxColor:arreColores[nivel]})      
                    });
                    for (let index = 0; index < arreColores.length; index++) 
                    {
                        let auxPos=[]
                        const filtrado=auxArregloColor.filter((color)=>color.auxColor===arreColores[index] )  
                        filtrado.forEach((color,indice)=>{                        
                            auxPos[indice] = `${color.pos}`
                        })              
                        auxArregloColor2.push({pos:auxPos,auxColor:arreColores[index]})      
                    }
                    console.log(auxArregloColor2);    
                    //setListGralMap(auxArregloColor2)
                    setListGralOrg(auxArregloColor2)
                } else{
                    aux.forEach((element,index )=> {                        
                        auxArregloColor.push({pos:`${element.Id}`,auxColor:arreColores[index]})      
                    });
                    //setListGralMap(auxArregloColor)
                    setListGralOrg(auxArregloColor)
                }                   
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral, history, source);
    };

    const llenaTotales = (auxTipo,auxReg) => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");         
        const data = qs.stringify({
            usuario: auxId,                             
            status:0,
            estado:15,
            municipio:auxReg,
            seccion:0,
            otro:0,
            fini:fInicio+'T00:00:00',
            ffin:fFinal+'T23:59:59',
            idDispositivo: auxDispV,
        });
        const url = "perfil/v2/avance";

        function respuesta(response) {
            if (response[0].Id !== -1) 
            {  //console.log(response);
                if (auxTipo===1) 
                {
                    setListAv(response[0].total)   
                    setListTotl(response) 
                    setTipoTabla(1)
                }else{
                    if (auxTipo===2) 
                    {
                        setListAvMun(response)
 
                    }
                    else if (auxTipo===3) 
                    {
                        setListAvMun(response)
                        setTipoTabla(3)
                    }
                }                                           
            }
        }
    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral,setEspera, history, source);
    };

    
    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();        
        setOpcion(auxValor)
    }

    const menu=(
        <Box display="flex" justifyContent="center">
        <Breadcrumbs separator="-" aria-label="breadcrumb"  >
            <Typography variant="h5" color={opcion === 1 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,1)}
            >
                Promovidos
            </Typography>
            <Typography variant="h5" color={opcion === 2 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,2)}
            >
                Recorridos
            </Typography>          
            <Typography variant="h5" color={opcion === 3 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,3)}
            >
                Usuarios
            </Typography>                                                                   
        </Breadcrumbs>
        </Box>
    )

    return (
    <div style={{display:"flex",flexDirection:"column"}}>

          {menu}
    {  opcion === 1 ?    
    <Box>
        <Box display={'flex'} pb={1}>
       {/*      <Box >
                <IconButton color="primary"  aria-label="add circle" 
                component="span" onClick={()=>setBuscar(!buscar)}>
                    <Tooltip title="Buscar">
                        <SearchIcon/>
                    </Tooltip>
                </IconButton>                                                                                                                              
            </Box> */}
            <Box >
                <IconButton color="primary"  aria-label="add circle" 
                component="span" onClick={reiniciar}>
                    <Tooltip title="Reiniciar">
                        <ReplayIcon/>
                    </Tooltip>
                </IconButton>                                                                                                                              
            </Box>
          
            <Box >
                <IntervaloSelect  fSelect={fSelect}  setFSelect={setFSelect}/>
            </Box>
            {listAv.length !== 0 ?
                <CardTotal valor={listAv[0]} dtoId={registro?.Id} />
            :null
            }
        </Box>
        
            <span>
                {listAv.length !== 0 ? 
                    <Graficas valor={listAv} />
                :null }
                <Box display={"flex"} flexDirection={"row"} >
                   { listGralMap.length !== 0 ?
                        <MapaDto  arregloColor={listGralMap} setMapRegis={setMapRegis} 
                            long={long} lat={lat} zoom={zoom} tipoTabla={tipoTabla}
                            regMun={regMun} registro={registro}
                        />       
                    :null} 
                    { listAvMun.length !== 0 || listTotl.length !== 0 ?
                        <TablaMun  listAvMun={listAvMun} registro={regMun} registroEdo={registro} 
                            setRegistro={setRegMun} tipoTabla={tipoTabla} 
                            listAvEdo={listTotl}
                        />       
                    :null}
                    {/* regMun!==null  ?
                        <GraficaPolar valor={regMun} />
                    :null */}
                    {listAvMun.length!==0 && regMun!==null  ?
                    <TablaSeccion listAv={listAvMun} registro={{}} setRegistro={()=>{}} 
                        fechas={fSelect}
                    />
                :null}
                </Box>    
            </span>
          
        </Box>
        : (opcion ===3 ? <AvancePerfilUsr/>:<AvanceRecorrido listAv={listAv} listAvMun={listAvMun} listTotl={listTotl} />)}      
    </div>
  )
}

export default AvanceDto