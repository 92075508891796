import React,{useState,useEffect} from 'react';
import {Box,LinearProgress,//IconButton,Typography
    IconButton,Tooltip,} from "@material-ui/core";
//import MenuIcon from '@material-ui/icons/Menu'; 
import SearchIcon from '@material-ui/icons/Search';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import TablaMunicipio from './TablaMunicipio';
import IntervaloSelect from '../perfiles/IntervaloTabla';
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import { obtenDispo } from '../funciones/datosLocal';
export default function MunicipioInicio(){
    const source = axios.CancelToken.source();
    let history = useHistory();
    const usuid =localStorage.getItem('UsuId') ;   
    const usuTipo =localStorage.getItem('Tipo') ;    
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);     
    const [listMuni,setListMuni] = useState([]);
    const [fSelect, setFSelect] = useState({inicio:"Thu Feb 02 2023 00:00:00 GMT-0600 (hora estándar central)",final:new Date()});
    const[actualizar,setActualizar]=useState(true);
    const[espera,setEspera]=useState(false);
    const auxDispV =obtenDispo()
 
    useEffect(()=>{         

        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');           
            if (usuario.length===0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});                                                 
            }
            //document.title="Municipio";
            //guardarGenerales({...generales,Titulo:'AdminCorcho'}) 
            getMunicipios()     
        }

        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[actualizar]);

    const getMunicipios = () => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");   
        const data = qs.stringify({                
            usuario: usuid,           
            fini: fInicio+'T00:00:00',
            ffin: fFinal+'T23:59:59',
            idDispositivo: auxDispV,
        });
        const url = "perfil/v2/lista-total";

        function respuesta(aux) {
            if (aux[0]  ) 
            {   setListMuni(aux);
            }         
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    };

    const generarLista = e =>{   
        e.preventDefault()    
        setActualizar(!actualizar)
       // setAuxBandera(1)
    }

    const tabla=<Box display={'flex'} flexDirection="column" >
                    <Box pb={1}>
                        <IconButton color="primary"  aria-label="add circle" 
                            component="span" onClick={generarLista}>
                            <Tooltip title="Buscar">
                                <SearchIcon/>
                            </Tooltip>
                        </IconButton>  
                        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
                    </Box>                
                    <Box>
                        <TablaMunicipio muni={listMuni}/>
                    </Box>
                </Box>
    const contenido = listMuni.length!==0 && !espera? (tabla) :  (<Box pt={1} sx={{width:'100%'}}><LinearProgress/> </Box>);
    return (
    <Box display="flex" flexDirection="row" 
        style={{ marginLeft:'1rem'}}
    >  
        {contenido}
    </Box>
    )
}
 