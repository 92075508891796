import React, {useEffect, useRef, useState} from "react";
import mapboxgl from 'mapbox-gl';
import { makeStyles } from '@material-ui/core/styles';
//import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';

const MapGam="https://daduva-cartografia.sfo3.cdn.digitaloceanspaces.com/21/Puebla2023.geojson"
const useStyles = makeStyles({
    miclase:{".map-container":{
        height: "400px",
      }},
  });
  let arregloReg=[
    {  // region:1,
        pos:["001", "003", "026", "045","056","071","079","102", "112"],
        auxColor: process.env.REACT_APP_Fondo_Color
    }, 
    {   //region:2,
        pos:["014" ,"042", "047", "048","064","074","085","124"],
        "auxColor": process.env.REACT_APP_Fondo_Color
    },
]


function MapDelegacion({regSelect,arregloColor}) {

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
    const classes = useStyles();
    const mapContainer = useRef(null); 
    const [long] = useState(process.env.REACT_APP_Long); 
    const [lat] = useState(process.env.REACT_APP_Lat);
    const [zoom] = useState(process.env.REACT_APP_Zoom);
    const [hoveredDistrict, _setHoveredDistrict] = useState(null);
    const hoveredDistrictRef = useRef(hoveredDistrict);

    let hoveredStateId = null;
    let stateName = null;

    const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    useEffect(() => {        
        let nuevoArr=[
            'match',
            ['get', 'CVE_MUN'],                         
        ]
        arregloColor.forEach((elemento)=>{
            nuevoArr.push(elemento.pos)
            nuevoArr.push(elemento.auxColor)
        }) 
        nuevoArr.push(/* other  */'#cedded')        
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v10",
            center: [long, lat],
            zoom: zoom            
        });
        
        map.addControl(new mapboxgl.NavigationControl());

        map.on('load', () => {
            map.addSource('states', {
                type: 'geojson',
                data:MapGam,
            });

            map.addLayer({
                'id': 'state-fills',
                'type': 'fill',
                'source': 'states',
                'layout': {},
                'paint': {                    
                    'fill-color':nuevoArr,
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.3,
                        .75
                    ]
                }
            });

            map.addLayer({
                'id': 'state-line1',
                'type': 'line',
                'source': 'states',
                'layout': {},
                'paint': {                    
                    'line-color':'#0097A7',
                     
                }
            });

            map.on('mousemove', 'state-fills', (e) => {
                if (e.features.length > 0) {
                    if (hoveredStateId !== null) {
                        map.setFeatureState(
                            {source: 'states', id: hoveredStateId},
                            {hover: false}
                        );
                    }
                    hoveredStateId = e.features[0].id;
                    map.setFeatureState(
                        {source: 'states', id: hoveredStateId},
                        {hover: true}
                    );
                    stateName = e.features[0].properties.NOMGEO;
                    _setHoveredDistrict(stateName)
                }
            });

            map.on('mouseleave', 'state-fills', () => {
                if (hoveredStateId !== null) {
                    map.setFeatureState(
                        {source: 'states', id: hoveredStateId},
                        {hover: false}
                    );
                }
                hoveredStateId = null;
            });


        });  
     
    }, [arregloColor]);


    return (
    <div className={classes.miclase} style={{width:480,height: "17rem"}} >
                  <div className="info">
                Municipio  <strong>{hoveredDistrict ? hoveredDistrict : ""}</strong>
            </div>   
        {<div id="districtDetailMap" className={"map"} >
            <div style={{width:580,height: 595}} ref={mapContainer}>
            </div>
        </div>}
    </div>
    );
}

export default MapDelegacion;