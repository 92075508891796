import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box, IconButton, LinearProgress, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tooltip, TablePagination} from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import { celda, } from "../generales/TablaGeneral";
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ModalMapa from "./ModalMapa";
import { obtenIdUsu} from '../funciones/datosLocal';
import CreateIcon from '@material-ui/icons/Create';
import ModalEditRecorrido from "./ModNvoEditRecorrido";
import PaginacionTab from '../layout/PaginacionTab'
const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
        //maxWidth:"60rem"
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaRecorridos({ history, fechasSeleccionadas, selectedID, setSelectedID, source, buscar,
                                            checked, abrirModal, setAbrirModal, setRecorrido,filtro }) {
                                                let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
    let filter = 0
    const classes = useStyles();
    const idusu = obtenIdUsu();
    const [espera, setEspera] = useState(false);    
    const [sinDatos, guardarSinDatos] = useState(true)
    const [open, setOpen] = useState(false);
    const [seleccionado, setSeleccionado] = useState([]);
    const [modalEdit, setModalEdit] = useState(false);
    const [recarga, setRecarga] = useState(false);
    const [totalPaginas,setTotalPaginas]=useState(1);
    const [numPagina,setNumPagina]=useState(0);
    const [listRecorridos, setListRecorridos] = useState([])
    const [datos, guardarDatos] = useState([])

    useEffect(() => {
        

        llenarTabla();
    }, [buscar,recarga]);

    const llenarTabla = () => {
        
        if (checked) {
            filter = idusu
        } else {
            filter = 0
        }

        const data = qs.stringify({
            Usuario: idusu,
            Fini: fInicio + 'T00:00:00',
            Ffin: fFinal + 'T23:59:59',
            seccion:filtro.Seccion!==""?filtro.Seccion :0,
            direccion:filtro.direccion.length >= 3 ?filtro.direccion :"" ,
            observ:filtro.observacion.length >= 3 ?filtro.observacion :"",
            idDispositivo: '45vf2d1vdf21d2f'
        });
        const url = "recorrido/filtro";

        function response(data) {
            if (data[0].Id !== -1 && data[0].Id !== 0) {
                guardarSinDatos(true)
                guardarDatos(data)
                if (data.length>300) {
       
                    const auxTam = data.length;
                    if(auxTam%300===0)
                    {
                        const pagina=Math.trunc(auxTam/300)
                        setTotalPaginas(pagina)   
                    }
                    else
                    {   let pagina=Math.trunc(auxTam/300)
                        pagina=pagina+1;
                        setTotalPaginas(pagina)
                    }
                    setNumPagina(0) 
                    setListRecorridos(data.slice(0,300))                   
                }
                else{
                    setTotalPaginas(1)
                    setNumPagina(0)        
                    setListRecorridos(data)          
                }                
                
            } else {
                guardarSinDatos(false)            
            }
        }

        llamaApiCargaToken(data, url, response, ErrorGeneral, setEspera, history, source);
    };

    const seleccionar = (auxId, auxOrden) => {
        setSelectedID(auxId)
        setRecorrido(auxOrden)
        setSeleccionado(auxOrden)
    }

    const abrirModalMapa = () => {
        setAbrirModal(true)
    }

    const modalMapa = abrirModal ? <ModalMapa abrirModal={abrirModal} setAbrirModal={setAbrirModal}  datos={seleccionado}/> : null

    function verMas(auxId) {
        setSelectedID(auxId)
        if (auxId === selectedID) {
            setOpen(!open)
        } else {
            setOpen(false)
        }
    }    

    function Row(props) {
        const {row} = props;
        return (        
        <TableRow onClick={() => { seleccionar(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >     
             {idusu ===1 ?
             <TableCell className={classes.tableCell} align="center" padding={'none'} width={65}
             style={{fontSize: 12 + 'px'}}  >
                {row.Id}
            </TableCell>
            :null}       
            {row.Lat && row.Lon !== 0.0 ?
                <TableCell align="center" padding={'none'} style={{width: 30}}>
                    <Tooltip title={'Ver más'}>
                        <IconButton color="primary" size="small"
                            onClick={() => abrirModalMapa()}>
                            {selectedID === row.Id ?
                                <LocationOnIcon fontSize="small" style={{color: 'white'}}/> 
                                : <LocationOnIcon fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                </TableCell> 
                : <TableCell align="center" padding={'none'} style={{width: 30}}></TableCell>
            }
            {idusu ===1 ?
             <TableCell className={classes.tableCell} align="center" padding={'none'} width={65}
             style={{fontSize: 12 + 'px'}}  >
               <Tooltip title={'Editar Recorrido'}>
                    <IconButton color="primary" 
                        onClick={() => setModalEdit(true)}>                            
                            <CreateIcon fontSize="small" color={selectedID === row.Id ?"inherit":"primary"}/>                                 
                    </IconButton>
                    </Tooltip>
            </TableCell>
            :null}  
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                style={{fontSize: 12 + 'px'}} >
                {moment.utc(row.FReg).local(true).format("DD/MM/YYYY HH:mm")}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                style={{fontSize: 12 + 'px'}} >
                {moment.utc(row.FEnv).local(true).format("DD/MM/YYYY HH:mm")}
            </TableCell>
            <TableCell className={classes.tableCell} align="left" padding={'none'} width={180}
                style={{fontSize: 12 + 'px'}} >
                {row.UsrNom}
            </TableCell>
            <TableCell className={classes.tableCell} align="left" padding={'none'} width={120} 
                style={{fontSize: 12 + 'px'}}
            >
                {row.TipoNom}
            </TableCell>
            <TableCell className={classes.tableCell} align="center" padding={'none'} width={70} >
                {row.Cantidad}
            </TableCell>

            <TableCell className={classes.tableCell} align="center" padding={'none'} width={55} >
                {row.SeccNom}
            </TableCell>
            <TableCell className={classes.tableCell} align="left" padding={'none'} style={{fontSize: 11 + 'px'}}
                    width={250}>
                {row.Direcc}
            </TableCell>
            {celda( "left", {width:"150px",fontSize:"12px"}, row.Obsrv, 11, classes.tableCell)}
        </TableRow>                    
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                      
                    {idusu ===1 ?
                    <TableCell align="center" padding='none'></TableCell>                            
                    :null}
                    {idusu ===1 ?
                    <TableCell align="center" padding='none'></TableCell>                            
                    :null}
                    <TableCell align="center" padding='none'>{auxlista.length}</TableCell>                            
                    <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                    <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                    <TableCell align="center" padding='none'>Nombre</TableCell>
                    <TableCell align="center" padding='none'>Tipo</TableCell>
                    <TableCell align="center" padding='none'>Cantidad</TableCell> 
                   {/*  <TableCell align="center" padding='none'>Estado</TableCell>
                    <TableCell align="center" padding='none'>Municipio</TableCell> */}
                    <TableCell align="center" padding='none'>Sección</TableCell>
                    <TableCell align="center" padding='none'>Dirección</TableCell>
                    <TableCell align="center" padding='none'>Observación</TableCell>  
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length != 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    function onChangePagina(event, newPage){
        event.preventDefault();
        if (newPage ===0) 
        {   setListRecorridos(datos.slice(0,300))   
        }
        else
        {   setListRecorridos(datos.slice(newPage * 300, newPage * 300 + 300))   
        }
        setNumPagina(newPage)
    }

    function generarFooter(){
        return(
        <TablePagination  style={{padding:0}}
          rowsPerPageOptions={false}
          colSpan={3} rowsPerPage={300}
          count={datos.length} page={numPagina}
          lang='es'  onPageChange={onChangePagina}
          ActionsComponent={PaginacionTab}
          labelDisplayedRows={
            ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
            }
          }
        />)
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'></TableCell>
                            <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                            <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                            <TableCell align="center" padding='none'>Nombre</TableCell>
                            <TableCell align="center" padding='none'>Tipo</TableCell>
                            <TableCell align="center" padding='none'>Estado</TableCell>
                            <TableCell align="center" padding='none'>Municipio</TableCell>
                            <TableCell align="center" padding='none'>Sección</TableCell>
                            <TableCell align="center" padding='none'>Dirección</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Recorridos</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const paginacion = totalPaginas>1 ? generarFooter() : null
    const modEditarRecor = modalEdit ? <ModalEditRecorrido abrirModal={modalEdit} registro={seleccionado}
                                            setAbrirModal={setModalEdit} latitud={0}
                                            longitud={0} actualiza={()=>setRecarga(!recarga)} titulo={`Editar Registro ${seleccionado.Id}`}
                                        /> : null

    const contenido = sinDatos && listRecorridos.length != 0 ? tablaCompleta(listRecorridos) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
    <Paper>
        {modEditarRecor}
        {paginacion}
        {tabla}
        {modalMapa}
    </Paper>
    );
}